export enum QuestionType {
  SINGLE_CORRECT = 1,
  MULTIPLE_CORRECT = 2,
}

export type Assessment = {
  id: number
  title: string
  duration: number
  totalNumberOfQuestions?: number | null
  isActive: boolean
}

export type AssessmentPerformance = {
  id: number
  question: {
    id: number
    text: string
    questionType: QuestionType
    options: string[]
  }
  response: number[]
}

export type Attempt = {
  id: number
  title: string
  duration: number // in seconds
  startTime: string // ISODate
  tempAssessmentPerformances: AssessmentPerformance[]
}

export type AutoSubmitted = {
  message: string
  autoSubmitted: true
}
