export const routes = {
  sso: "/",
  home: "/home",
  explore: "/explore",
  logout: "/logout",

  completeProfile: "/complete-profile",

  schoolProfile: "/school/:slug",
  // jobDetails: "/job/:jobId",
  acceptedOffer: "/job/accepted-offer",

  attemptAssessment: "/assessment/:jobApplicantStepId/attempt",

  attemptInterview: "/interview/",
  waitingForHost: "/interview/waiting-for-host/:interviewId",

  jobTimeline: "/job/:jobId/:jobApplicant/timeline",
  viewOffer: "/job/:jobOfferId/offer",

  waitingList: "/waiting-list",
  pageNotFound: "/*",
  error: "/E88iUJijnM",
} as const
