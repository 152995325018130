import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  profile: {
    retrieve: slug => getNebulaURL(`/v1/schools/${slug}/profile/`),
    update: () => getNebulaURL("/v1/schools/"),
  },

  perks: {
    list: () => getNebulaURL("/v1/schools/perks/"),
  },

  amenities: {
    list: () => getNebulaURL("/v1/schools/amenities/"),
  },

  gallery: {
    list: slug => getNebulaURL(`/v1/schools/${slug}/gallery/`),
  },

  interestedUser: {
    create: schoolId =>
      getNebulaURL(`/v1/schools/${schoolId}/interested-users/`),
  },
})
