import React from "react"

import { Chip, Tag, Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { createUseStyles, useTheme } from "react-jss"

import { Calendar, MoneySquare, PinAlt } from "iconoir-react"

import { Job, JobOfferDetailsType } from "api/resources/jobs/types"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import TruncatedText from "components/shared/TruncatedText"

const useStyles = createUseStyles(theme => ({
  avatar: {
    height: 142,
    width: 142,
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      height: 42,
      width: 42,
    },
  },
  description: {
    whiteSpace: "pre-line",
  },
}))

const JobPostCard = ({
  job,
  jobOfferDetails,
}: {
  job: Job
  jobOfferDetails?: JobOfferDetailsType
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  return (
    <Section className="p-1">
      <SectionContent>
        <div>
          <div className="flex items-center gap-1 flex-wrap">
            <Typography variant="strong">{job.position}</Typography>
            {job.school.curriculumBoard.map(item => (
              <Chip
                key={item.uuid}
                label={item.name}
                size="sm"
                variant="outlined"
              />
            ))}
          </div>
          <Typography color="onSurface.500" variant="body">
            {job.school.name}
            {job.school.country && job.school.state && (
              <span>
                , {job.school.state.name}, {job.school.country.name}
              </span>
            )}
          </Typography>

          {job.school.curriculumBoard && job.school.curriculumBoard.length > 1 && (
            <div className="flex gap-1 mt-3">
              {job.school.curriculumBoard.map((item, index) => (
                <Tag className="mb-2 mt-1" key={index} label={item.name} />
              ))}
            </div>
          )}
          <div className="flex gap-4 mt-3">
            {job.school.country && job.school.state && (
              <div className="flex flex-col gap-0.5">
                <div className="flex gap-1 items-center">
                  <PinAlt color={theme.colors.onSurface[500]} />
                  <Typography color="onSurface.500" variant="strongSmallBody">
                    Location
                  </Typography>
                </div>
                <Typography display="inline" variant="strongSmallBody">
                  {job.school.state.name}, {job.school.country.name}
                </Typography>
              </div>
            )}

            <div className="flex flex-col gap-0.5">
              <div className="flex gap-1 items-center">
                <MoneySquare color={theme.colors.onSurface[500]} />
                <Typography color="onSurface.500" variant="strongSmallBody">
                  Salary Offered
                </Typography>
              </div>
              <div>
                <Typography display="inline" variant="strongSmallBody">
                  {jobOfferDetails
                    ? jobOfferDetails.currency.symbol
                    : job.currency.symbol}
                  {(jobOfferDetails
                    ? jobOfferDetails.finalSalary
                    : job.salaryOffered
                  ).toLocaleString()}{" "}
                </Typography>
                <Typography display="inline" variant="body">
                  per year
                </Typography>
              </div>
            </div>
            {jobOfferDetails && (
              <div className="flex flex-col gap-0.5">
                <div className="flex gap-1 items-center">
                  <Calendar color={theme.colors.onSurface[500]} />
                  <Typography color="onSurface.500" variant="strongSmallBody">
                    Date of Joining
                  </Typography>
                </div>
                <Typography display="inline" variant="strongSmallBody">
                  {format(new Date(jobOfferDetails.joiningDate), "d MMMM yyyy")}
                </Typography>
              </div>
            )}
          </div>
          {(job.description ||
            (jobOfferDetails && jobOfferDetails.description)) && (
            <TruncatedText
              className={clsx(classes.description, "mt-1")}
              color="onSurface.600"
              maxLength={400}
              variant="body"
            >
              {jobOfferDetails ? jobOfferDetails.description : job.description}
            </TruncatedText>
          )}
        </div>
      </SectionContent>
    </Section>
  )
}

export default JobPostCard
