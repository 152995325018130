import React, { useContext, useState } from "react"

import { Button } from "@suraasa/placebo-ui"
import { useFormContext } from "react-hook-form"

import { ArrowRight } from "iconoir-react"

import api from "api"
import AnsweredQuestion from "components/ProfileCompletion/AnsweredQuestion"
import {
  MARKETING_QUESTIONS as QUESTIONS,
  STEPS,
} from "components/ProfileCompletion/constants"
import AreYouCurrentlyTeaching from "components/ProfileCompletion/Form/AreYouCurrentlyTeaching"
import Grades from "components/ProfileCompletion/Form/Grades"
import Subjects from "components/ProfileCompletion/Form/Subjects"
import WhereDoYouWantToTeach from "components/ProfileCompletion/Form/WhereDoYouWantToTeach"
import Heading from "components/ProfileCompletion/Heading"
import {
  Form,
  ProfileCompletionContext,
} from "components/ProfileCompletion/utils"
import toast from "utils/toast"

import LookingForJobs from "./LookingForJobs"

const formatData = ({
  lookingForJobs,
  areYouCurrentlyTeaching,
  ...data
}: Form) => ({
  lookingForJobs: {
    question: QUESTIONS.lookingForJobs,
    response: lookingForJobs ? "yes" : "no",
  },

  currentlyTeaching: {
    question: QUESTIONS.currentlyTeaching,
    response: areYouCurrentlyTeaching ? "yes" : "no",
  },

  subjects: {
    question: (() => {
      if (lookingForJobs) return QUESTIONS.subjects.lookingForJobs
      if (areYouCurrentlyTeaching)
        return QUESTIONS.subjects.areYouCurrentlyTeaching
      return QUESTIONS.subjects.notLookingForJobsAndNotCurrentlyTeaching
    })(),
    response: (lookingForJobs ? data.subjectId : data.teachingSubjectsId)?.map(
      i => i.value
    ),
  },

  grades:
    areYouCurrentlyTeaching === true
      ? {
          question: QUESTIONS.grades,
          response: lookingForJobs
            ? data.gradeId?.map(i => i.value)
            : areYouCurrentlyTeaching &&
              data.teachingGradesId?.map(i => i.value),
        }
      : undefined,

  preferredWorkLocations: lookingForJobs
    ? {
        question: QUESTIONS.preferredWorkLocations,
        response: {
          countryId: data.countryId ?? null,
          stateId: data.stateId ?? null,
        },
      }
    : undefined,
})

const MarketingData = () => {
  const {
    watch,
    setValue,
    trigger,
    reset,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<Form>()

  const { setStep } = useContext(ProfileCompletionContext)

  const {
    //  Looking for Jobs
    lookingForJobs,
    subjectId,
    gradeId,
    countryId,
    stateId,
    // Not Looking for Jobs
    areYouCurrentlyTeaching,
    teachingSubjectsId,
    teachingGradesId,
  } = watch()

  const [cursor, setCursor] = useState<keyof Form>("lookingForJobs")

  const onSubmit = handleSubmit(async formData => {
    const res = await api.jobs.submitMarketingData({
      data: formatData(formData),
    })
    if (res.isSuccessful) {
      setStep(STEPS.AcademicDetails)
    } else {
      toast.error(res.errors.message)
    }
  })

  const handleContinue = () => {
    switch (cursor) {
      case "subjectId":
        if (subjectId && subjectId.length > 0) {
          setCursor("gradeId")
        } else {
          trigger("subjectId")
        }
        break

      case "gradeId":
        if (gradeId && gradeId.length > 0) {
          setCursor("countryId")
        } else {
          trigger("gradeId")
        }
        break

      case "countryId":
        if (countryId && stateId) {
          onSubmit()
        } else if (!countryId) {
          trigger("countryId")
        } else if (!stateId) {
          trigger("stateId")
        }
        break

      case "teachingSubjectsId":
        if (teachingSubjectsId && teachingSubjectsId.length > 0) {
          if (areYouCurrentlyTeaching) {
            setCursor("teachingGradesId")
          } else {
            onSubmit()
          }
        } else {
          trigger("teachingSubjectsId")
        }
        break

      case "teachingGradesId":
        if (teachingGradesId && teachingGradesId.length > 0) {
          onSubmit()
        } else {
          trigger("teachingGradesId")
        }
        break

      default:
        break
    }
  }

  const handleChange = (value: typeof cursor) => {
    switch (value) {
      case "lookingForJobs":
        reset({ lookingForJobs })
        break
      case "subjectId":
        // setValue("subjectId", null)
        setValue("gradeId", null)
        setValue("countryId", null)
        setValue("stateId", null)
        break

      case "gradeId":
        // setValue("gradeId", null)
        setValue("countryId", null)
        setValue("stateId", null)
        break

      case "areYouCurrentlyTeaching":
        // setValue("areYouCurrentlyTeaching", null)
        setValue("teachingSubjectsId", null)
        setValue("teachingGradesId", null)
        break

      case "teachingSubjectsId":
        // setValue("teachingSubjectsId", null)
        setValue("teachingGradesId", null)
        break
      case "teachingGradesId":
        // setValue("teachingGradesId", null)
        break
      default:
        break
    }
    clearErrors()
    setCursor(value)
  }

  return (
    <div className="flex flex-col">
      <Heading
        subtitle="You need to answer few questions only the first time you apply for a job through Suraasa"
        title="Work Details"
      />

      {cursor === "lookingForJobs" && (
        <LookingForJobs
          handleClick={value => {
            if (value) {
              setCursor("subjectId")
            } else {
              setCursor("areYouCurrentlyTeaching")
            }
          }}
        />
      )}

      {cursor !== "lookingForJobs" && lookingForJobs !== null && (
        <AnsweredQuestion
          actionProps={{
            onClick: () => handleChange("lookingForJobs"),
          }}
          answer={lookingForJobs ? "Yes" : "No"}
          title={QUESTIONS.lookingForJobs}
        />
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {cursor !== "lookingForJobs" ? (
        lookingForJobs ? (
          <>
            {cursor === "subjectId" && (
              <Subjects
                label={QUESTIONS.subjects.lookingForJobs}
                name="subjectId"
              />
            )}
            {cursor !== "subjectId" && subjectId && (
              <AnsweredQuestion
                actionProps={{
                  label: "Change Subjects",
                  onClick: () => handleChange("subjectId"),
                }}
                answer={subjectId.map(item => item.label)}
                title={QUESTIONS.subjects.lookingForJobs}
              />
            )}

            {cursor === "gradeId" && (
              <Grades label={QUESTIONS.grades} name="gradeId" />
            )}

            {cursor !== "gradeId" && gradeId && (
              <AnsweredQuestion
                actionProps={{
                  label: "Change Grades",
                  onClick: () => handleChange("gradeId"),
                }}
                answer={gradeId.map(item => item.label)}
                title={QUESTIONS.grades}
              />
            )}

            {cursor === "countryId" && <WhereDoYouWantToTeach />}
          </>
        ) : (
          <>
            {cursor === "areYouCurrentlyTeaching" && (
              <AreYouCurrentlyTeaching
                handleClick={() => setCursor("teachingSubjectsId")}
              />
            )}

            {cursor !== "areYouCurrentlyTeaching" &&
              areYouCurrentlyTeaching !== null && (
                <AnsweredQuestion
                  actionProps={{
                    onClick: () => handleChange("areYouCurrentlyTeaching"),
                  }}
                  answer={areYouCurrentlyTeaching ? "Yes" : "No"}
                  title={QUESTIONS.currentlyTeaching}
                />
              )}

            {cursor === "teachingSubjectsId" && (
              <Subjects
                label={
                  areYouCurrentlyTeaching
                    ? QUESTIONS.subjects.areYouCurrentlyTeaching
                    : QUESTIONS.subjects
                        .notLookingForJobsAndNotCurrentlyTeaching
                }
                name="teachingSubjectsId"
              />
            )}

            {cursor !== "teachingSubjectsId" && teachingSubjectsId && (
              <AnsweredQuestion
                actionProps={{
                  label: "Change Subjects",
                  onClick: () => handleChange("teachingSubjectsId"),
                }}
                answer={teachingSubjectsId.map(item => item.label)}
                title={QUESTIONS.preferredWorkLocations}
              />
            )}

            {cursor === "teachingGradesId" && (
              <Grades label={QUESTIONS.grades} name="teachingGradesId" />
            )}

            {cursor !== "teachingGradesId" && teachingGradesId && (
              <AnsweredQuestion
                actionProps={{
                  label: "Change Grades",
                  onClick: () => handleChange("teachingGradesId"),
                }}
                answer={teachingGradesId.map(item => item.label)}
                title={QUESTIONS.grades}
              />
            )}
          </>
        )
      ) : null}

      {cursor !== "areYouCurrentlyTeaching" && cursor !== "lookingForJobs" && (
        <Button
          className="my-5 self-center md:self-start"
          endAdornment={<ArrowRight />}
          loading={isSubmitting}
          onClick={handleContinue}
        >
          Continue
        </Button>
      )}
    </div>
  )
}

export default MarketingData
