import React, { useState } from "react"

import { DialogProps, Typography } from "@suraasa/placebo-ui"

import api from "api"
import { InvitationStatus } from "api/resources/jobs/types"
import SharedDialog from "components/SharedDialog"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

type Props = {
  handleClose: ToggleValue
  afterSubmit?: () => void
  jobPosition: string
  schoolName: string
  subjectName: string
  /**
   * jobApplicant is needed only
   * in the case where we need to add interview
   * Note: This can be either string or number
   * if it comes from backend --> number
   * if it comes from url params --> string
   */
  jobApplicantId: string | number
} & Pick<DialogProps, "open">

const AcceptApplicationDialog = ({
  open,
  jobApplicantId,
  afterSubmit,
  handleClose,
  schoolName,
  jobPosition,
  subjectName,
}: Props) => {
  const [dialogLoading, setDialogLoading] = useState(false)
  const handleAction = async (status: InvitationStatus) => {
    setDialogLoading(true)

    const res = await api.jobs.jobApplicant.update({
      urlParams: {
        jobApplicantId,
      },
      data: {
        invitationStatus: status,
      },
    })

    if (res.isSuccessful) {
      if (afterSubmit) {
        afterSubmit()
      }
    } else {
      toast.error(res.errors.message)
    }
    setDialogLoading(false)
  }

  return (
    <>
      <SharedDialog
        actionLabel="Accept"
        handleClose={handleClose}
        loading={dialogLoading}
        open={open}
        title="Accept Invite"
        onConfirm={() => {
          handleAction(InvitationStatus.ACCEPTED)
        }}
      >
        <Typography>
          Accept <b>{schoolName}</b>’s job opening for&nbsp;
          <b>
            {jobPosition}, {subjectName}
          </b>
          ?
        </Typography>
      </SharedDialog>
    </>
  )
}

export default AcceptApplicationDialog
