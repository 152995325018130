import React, { createContext, useEffect, useState } from "react"

import { NotificationList, useNotifications } from "@suraasa/notifications"
import { JobsInUsaCard } from "@suraasa/utils"
import { Outlet } from "react-router-dom"

import api from "api"
import axios from "api/axios"
import { AcceptedJobOverview, StepsOverview } from "api/resources/jobs/types"
import CheckEnrollment from "components/shared/CheckEnrollment"
import { getAuthInfo, saveAuthInfo } from "utils/auth"
import { NotificationAction } from "utils/constants"

export type Context = {
  overview: {
    set: React.Dispatch<React.SetStateAction<AcceptedJobOverview>>
  } & AcceptedJobOverview
  stepsOverview: StepsOverview & {
    loading: boolean
    _extra?: { autoRedirect: boolean }
  }
  setStepsOverview: React.Dispatch<
    React.SetStateAction<StepsOverview & { loading: boolean }>
  >
  notifications: NotificationList<NotificationAction>
}

const defaultValues = {
  overview: {
    jobsLockedTill: "",
    set: () => {},
  },

  stepsOverview: {
    marketingFormDataExists: false,
    profileDataExists: false,
    marketingSignupComplete: false,
    qualificationDataExists: false,
    loading: true,
    _extra: {
      autoRedirect: true,
    },
  },
  setStepsOverview: () => {},
  notifications: { data: null, unreadCount: 0, totalCount: 0 },
} as const

export const GlobalContext = createContext<Context>(defaultValues)

const GlobalState = () => {
  const [overview, setOverview] = useState<AcceptedJobOverview>({
    jobsLockedTill: "",
  })

  const [stepsOverview, setStepsOverview] = useState<Context["stepsOverview"]>(
    defaultValues.stepsOverview
  )

  const [notifications] = useNotifications({ axiosInstance: axios })

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.jobs.stepsOverview()
      if (res.isSuccessful) {
        setStepsOverview(prevState => ({
          ...prevState,
          ...res.data,
          loading: false,
        }))
      }
    }

    if (getAuthInfo()) fetchData()
    else setStepsOverview(prevState => ({ ...prevState, loading: false }))
  }, [setStepsOverview])

  useEffect(() => {
    const fetchProfile = async () => {
      const res = await api.profile.retrieve()
      if (res.isSuccessful) {
        const auth = getAuthInfo()
        if (auth)
          saveAuthInfo({
            ...auth,
            user: { ...auth.user, photo: res.data.picture || "" },
          })
      }
    }

    if (getAuthInfo()) fetchProfile()
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        overview: { ...overview, set: setOverview },
        stepsOverview,
        setStepsOverview,

        notifications,
      }}
    >
      <Outlet />
      <CheckEnrollment
        items={[
          {
            type: "course",
            slug: "boosting-employability",
          },
          {
            type: "qualification",
            slug: "professional-graduate-certificate-in-teaching-learning",
          },
        ]}
      >
        <JobsInUsaCard />
      </CheckEnrollment>
    </GlobalContext.Provider>
  )
}

export default GlobalState
