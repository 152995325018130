import React from "react"

import { Button, Chip, IconButton, Tag, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { formatDistance } from "date-fns"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"

import { Check, ShareAndroid } from "iconoir-react"

import {
  ApplicationStatus,
  InvitationStatus,
  Job,
} from "api/resources/jobs/types"
import ShareJobButton from "components/jobs/ShareJobButton"
import SchoolLogo from "components/shared/SchoolLogo"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    cursor: "pointer",
    width: "100%",
  },
  jobTitle: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card: {
    background: "white",
    borderRadius: "8px",
    border: `1px solid ${theme.colors.surface[200]}`,
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
    },
    "&:focus": {
      outline: "1px solid black",
    },
  },
  logo: {
    filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.05))",
    width: "46px",
    height: "46px",
  },
}))

type Props = {
  data: Job
}

const JobCard = ({ data }: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const timeDistance = (time: string) =>
    formatDistance(new Date(time), new Date(), {
      addSuffix: true,
    })

  const getCTA = () => {
    const applyButton = (
      <Button
        component={Link}
        size="sm"
        to={`${routes.schoolProfile.replace(
          ":slug",
          data.school.slug
        )}?jobSlug=${data.slug}&applyingOnJobId=${data.id}`}
        variant="outlined"
      >
        Apply
      </Button>
    )

    const applicationButton = (
      <Button
        component={Link}
        size="sm"
        to={`${routes.jobTimeline
          .replace(":jobId", String(data.id))
          .replace(
            ":jobApplicant",
            data.jobApplicant ? data.jobApplicant.id.toString() : "-1"
          )}`}
        variant="outlined"
      >
        View Application
      </Button>
    )

    const invitationButton = (
      <Button
        component={Link}
        size="sm"
        to={`${routes.schoolProfile.replace(
          ":slug",
          data.school.slug
        )}?jobSlug=${data.slug}`}
        variant="outlined"
      >
        View Invitation
      </Button>
    )

    if (!data.jobApplicant) return applyButton

    const { applicationStatus, invitationStatus } = data.jobApplicant

    if (applicationStatus === ApplicationStatus.PENDING) {
      return applicationButton
    }
    if (applicationStatus === ApplicationStatus.ACCEPTED) {
      return applicationButton
    }
    // User can not re-apply
    if (applicationStatus === ApplicationStatus.REJECTED) {
      return applicationButton
    }

    if (invitationStatus === InvitationStatus.PENDING) {
      return invitationButton
    }
    if (invitationStatus === InvitationStatus.ACCEPTED) {
      return invitationButton
    }
    // User can not re-apply
    if (invitationStatus === InvitationStatus.REJECTED) {
      return null
    }

    return applyButton
  }

  const getStatus = () => {
    const datePosted = (
      <Typography color="onSurface.500" variant="smallBody">
        {/* eslint-disable-next-line no-nested-ternary */}
        {data.datePublished
          ? timeDistance(data.datePublished) !== "less than a minute ago"
            ? `Posted ${timeDistance(data.datePublished)}`
            : "Posted recently"
          : ""}
      </Typography>
    )
    if (!data.jobApplicant) return datePosted

    const { applicationStatus, invitationStatus } = data.jobApplicant

    const chipProps = {
      color: "success",
      size: "sm",
      startAdornment: <Check strokeWidth={3} />,
    } as const

    if (applicationStatus === ApplicationStatus.PENDING) {
      return <Chip {...chipProps} label="Applied" />
    }
    if (applicationStatus === ApplicationStatus.ACCEPTED) {
      return <Chip {...chipProps} label="Accepted" />
    }
    if (applicationStatus === ApplicationStatus.REJECTED) {
      return <Chip {...chipProps} color="critical" label="Rejected" />
    }

    if (invitationStatus === InvitationStatus.PENDING) {
      return <Chip {...chipProps} label="Invited" />
    }
    if (invitationStatus === InvitationStatus.ACCEPTED) {
      return <Chip {...chipProps} label="Accepted" />
    }
    // This entry should not show up in explore page.
    if (invitationStatus === InvitationStatus.REJECTED) {
      return <Chip {...chipProps} color="critical" label="Rejected" />
    }

    return datePosted
  }

  return (
    <div
      className={classes.root}
      role="button"
      tabIndex={0}
      onClick={() => {
        navigate(
          `${routes.schoolProfile.replace(":slug", data.school.slug)}?jobSlug=${
            data.slug
          }`
        )
      }}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === "Space") {
          navigate(
            `${routes.schoolProfile.replace(
              ":slug",
              data.school.slug
            )}?jobSlug=${data.slug}`
          )
        }
      }}
    >
      <div
        className={clsx(
          "flex-col sm:flex-row flex p-3 justify-between mb-2",
          classes.card
        )}
      >
        <div className="flex gap-2 flex-col sm:flex-row">
          <SchoolLogo
            alt="logo"
            className={clsx(classes.logo)}
            src={data.school.logo}
          />
          <div className="flex flex-col gap-1.75">
            <div>
              {(data.position || data.subject) && (
                <Typography
                  className={classes.jobTitle}
                  display="inline"
                  variant="strong"
                >
                  {data.position ?? ""}
                  {data.subject && `, ${data.subject.name}`}
                </Typography>
              )}
              {data.school.name && (
                <Typography variant="body">{data.school.name}</Typography>
              )}
            </div>
            {(data.school.curriculumBoard.length ||
              (data.school.state && data.school.country)) && (
              <div className="flex gap-x-1 gap-y-2 flex-wrap">
                {data.school.curriculumBoard.map((board, index) => (
                  <Tag key={index} label={board.name} size="sm" />
                ))}
                {data.school.state && data.school.country && (
                  <Tag
                    color="onSurface"
                    label={`${data.school.state.name}, ${data.school.country.name}`}
                    size="sm"
                  />
                )}
              </div>
            )}
            {data.salaryOffered > 0 && (
              <div className="flex items-center flex-wrap">
                <Typography variant="strong">
                  {data.currency.code} {data.salaryOffered.toLocaleString()}
                  {data.maximumSalaryOffered ? (
                    <> - {data.maximumSalaryOffered.toLocaleString()}</>
                  ) : null}
                </Typography>
                <Typography color="onSurface.500" variant="body">
                  /year
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="flex sm:flex-col justify-between items-end">
          {getStatus()}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="flex items-center gap-1.25"
            /**
             * This onClick is important. We need this to prevent react-modal from firing the top level onClick which contains navigate().
             * If we remove this then a bug is introduced that fires the root onClick when you close the sharesheet.
             */
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <ShareJobButton
              activator={handleOpen => (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    handleOpen()
                  }}
                >
                  <ShareAndroid />
                </IconButton>
              )}
              jobSlug={data.slug}
              position={data.position}
              schoolProfile={data.school}
              subject={data.subject.name}
            />

            {getCTA()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobCard
