import React, { PropsWithChildren, useEffect } from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"

import { PropsWithClassName } from "types"
import { highlightElement } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  root: {
    scrollMargin: theme.spacing(5),
    background: "#fff",
    position: "relative",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(1),
  },
  stepConnector: {
    position: "absolute",
    height: 32,
    width: 2,
    bottom: -33,
    left: 31,
    background: theme.colors.onSurface[200],
  },
}))

type Props = {
  icon?: React.ReactElement
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const TimelineContent = ({
  icon: Icon,
  children,
  className,
  ...props
}: PropsWithChildren<PropsWithClassName<Props>>) => {
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const jobApplicantStepId = searchParams.get("jobApplicantStepId")

  // Highlight the timeline item that was opened through the notification
  useEffect(() => {
    if (jobApplicantStepId && jobApplicantStepId === props.id) {
      const timeouts = highlightElement({
        element: document.getElementById(jobApplicantStepId),
        onEnd: () => {
          searchParams.delete("jobApplicantStepId")
          setSearchParams(searchParams, { replace: true })
        },
      })

      return () => {
        timeouts.forEach(clearTimeout)
      }
    }
  }, [jobApplicantStepId, setSearchParams, searchParams, props.id])

  return (
    <div
      className={clsx(
        classes.root,
        "TimelineContent-root",
        "px-2 pt-3 pb-2 my-4",
        className
      )}
      {...props}
    >
      <div className="flex gap-1.5">
        <span className="TimelineContent-icon flex">{Icon}</span>
        {children}
      </div>
      <div
        className={clsx("TimelineContent-connector", classes.stepConnector)}
      />
    </div>
  )
}

export default TimelineContent
