import React from "react"

import {
  Button,
  InputLabel,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"

import { Plus } from "iconoir-react"

import { Evidence, EvidenceType } from "api/resources/profile/types"
import FileInput from "components/shared/FileInput"
import { UseArray } from "utils/hooks/useArray"
import useToggle from "utils/hooks/useToggle"

import PreviewFile from "./PreviewFile"
import UploadEvidenceUrlDialog from "./UploadEvidenceUrlDialog"

type Props = {
  limit?: number
  inputLabel?: string
  buttonLabel: string
  newEvidences: UseArray<File | string>
  evidenceFiles: UseArray<Evidence>
  handleEvidenceFilesToBeDeleted: UseArray<Evidence["id"]>["push"]
}

const UploadEvidenceSection = ({
  inputLabel,
  buttonLabel,
  limit,
  newEvidences,
  evidenceFiles,
  handleEvidenceFilesToBeDeleted,
}: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const [openEvidenceUrlDialog, toggleEvidenceUrlDialog] = useToggle(false)

  return (
    <div>
      <UploadEvidenceUrlDialog
        open={openEvidenceUrlDialog}
        toggle={toggleEvidenceUrlDialog}
        onAdd={url => newEvidences.push(url)}
      />
      {inputLabel && <InputLabel label={inputLabel} />}
      <FileInput
        accept="image/jpg,image/jpeg,image/png,application/pdf,application/doc,application/docx"
        name="evidence-files"
        ref={fileInputRef}
        onChange={files => newEvidences.push(files[0])}
      />
      <Menu
        menuButton={
          <Button
            disabled={Boolean(
              limit &&
                [...evidenceFiles.array, ...newEvidences.array].length >= limit
            )}
            size="sm"
            startAdornment={<Plus />}
            type="button"
            variant="outlined"
          >
            {buttonLabel}
          </Button>
        }
      >
        <MenuItem onClick={() => toggleEvidenceUrlDialog()}>Link/URL</MenuItem>
        <MenuItem onClick={() => fileInputRef.current?.click()}>
          Upload Document
        </MenuItem>
      </Menu>

      {[...evidenceFiles.array, ...newEvidences.array].length === 0 && (
        // TODO: placebo-issue support `component="li"` in Typography
        <ul className="pl-2 mt-1.5 mb-2">
          <Typography color="onSurface.500" variant="smallBody">
            <li>Should not exceed 5MB</li>
          </Typography>
          <Typography color="onSurface.500" variant="smallBody">
            <li>Accepted Formats - PDF, DOC, DOCX, JPEG, PNG</li>
          </Typography>
        </ul>
      )}

      <div className="flex items-center gap-3 mt-4 mb-2 flex-wrap">
        {newEvidences.array.map((item, index) => (
          <PreviewFile
            data={item}
            key={typeof item === "string" ? item : item?.name}
            onCancel={() => newEvidences.remove(index)}
          />
        ))}
        {evidenceFiles.array.map((item, index) => (
          <PreviewFile
            data={
              item.evidenceType === EvidenceType.File ? item.file : item.url
            }
            key={item.id}
            name={
              item.evidenceType === EvidenceType.File
                ? item.fileName
                : undefined
            }
            onCancel={() => {
              evidenceFiles.remove(index)
              handleEvidenceFilesToBeDeleted(item.id)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default UploadEvidenceSection
