import React, { Dispatch, SetStateAction } from "react"

import { Country, Subject } from "api/resources/global/types"
import { STEPS } from "components/ProfileCompletion/constants"
import { ValueOf } from "utils/helpers"
import { ToggleValue } from "utils/hooks/useToggle"

export interface ProfileCompletionContextType {
  open: boolean
  toggle?: ToggleValue
  step: ValueOf<typeof STEPS>
  setStep: Dispatch<SetStateAction<ProfileCompletionContextType["step"]>>
  countries: Country[]
  subjects: Subject[]
}

export const ProfileCompletionContext =
  React.createContext<ProfileCompletionContextType>({
    open: false,
    toggle: () => {},
    setStep: () => {},
    step: STEPS.JobOverview,
    subjects: [],
    countries: [],
  })

export type Option = { label: string; value: string }

export type Form = {
  lookingForJobs: boolean | null
  subjectId: Option[] | null
  gradeId: Option[] | null
  countryId: string | null
  stateId: string | null
  areYouCurrentlyTeaching: boolean | null
  teachingSubjectsId: Option[] | null
  teachingGradesId: Option[] | null
}
