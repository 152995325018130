import React, { useContext } from "react"

import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"

import { ShareAndroid } from "iconoir-react"

import api from "api"
import { Job, JobApplicant } from "api/resources/jobs/types"
import ShareJobButton from "components/jobs/ShareJobButton"
import ProfileCompletion from "components/ProfileCompletion"
import { getAuthInfo } from "utils/auth"
import { generateProfileCompletionURL, isProfileComplete } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"
import SchoolProfileContext from "views/schoolProfile/context"

const useStyles = createUseStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    width: "100%",
    border: `1px solid ${theme.colors.surface[200]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },
  bottomContainer: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function JobCard({
  position,
  salaryOffered,
  maximumSalaryOffered,
  subject,
  datePublished,
  currency: { code },
  jobApplicant,
  id,
  slug,
  onApply,
}: Job & { onApply: (id: number, jobApplicant: JobApplicant) => void }) {
  const classes = useStyles()
  const { profile } = useContext(SchoolProfileContext)
  const authInfo = getAuthInfo()

  const [searchParams] = useSearchParams()
  const jobSlug = searchParams.get("jobSlug")

  const [profileCompletionDialog, toggleProfileCompletionDialog] =
    useToggle(false)
  const { stepsOverview } = useContext(GlobalContext)

  const applyForJob = async (applyJobId?: string) => {
    const res = await api.jobs.jobApplicant.create({
      urlParams: { jobId: applyJobId ?? id },
    })

    if (res.isSuccessful) {
      toast.success("Applied for Job Successfully")
      /**
       * JobOverviewCard which is displayed on top
       * has the same job as one of the JobCard
       * we want to keep the `Applied` state in sync
       * when user applies through either of the cards
       */
      if (slug === jobSlug) {
        return window.location.reload()
      }
      onApply(id, res.data)
    } else toast.error(res.errors.message || "Something went wrong")
  }

  const handleApplyForJob = async () => {
    if (!authInfo) {
      const href = generateProfileCompletionURL({
        jobId: id.toString(),
      })
      if (href) {
        return (window.location.href = href)
      }
    } else if (!isProfileComplete(stepsOverview)) {
      toggleProfileCompletionDialog()
    } else {
      await applyForJob()
    }
  }

  return (
    <>
      {stepsOverview && profileCompletionDialog && (
        <ProfileCompletion
          jobId={id}
          open={profileCompletionDialog}
          toggle={toggleProfileCompletionDialog}
        />
      )}

      <div className={clsx("flex flex-col justify-between", classes.container)}>
        <div className="flex justify-between px-2 py-2">
          <div className="flex flex-col gap-0.5">
            <Typography variant="strong">{position}</Typography>
            <div className="flex items-center flex-wrap">
              <Typography variant="strong">
                {code} {salaryOffered.toLocaleString()}
                {maximumSalaryOffered ? (
                  <> - {maximumSalaryOffered.toLocaleString()}</>
                ) : null}
              </Typography>
              <Typography color="onSurface.500" variant="body">
                /year
              </Typography>
            </div>
            <Typography color="onSurface.500" variant="body">
              {subject.name}
            </Typography>
          </div>

          <ShareJobButton
            activator={handleOpen => (
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  handleOpen()
                }}
              >
                <ShareAndroid />
              </IconButton>
            )}
            jobSlug={slug}
            position={position}
            schoolProfile={profile}
            subject={subject.name}
          />
        </div>
        <div
          className={clsx(
            "flex items-center justify-between px-2 py-1",
            classes.bottomContainer
          )}
        >
          <Typography color="onSurface.500" variant="smallBody">
            {datePublished &&
              `Posted on ${format(new Date(datePublished), "do MMM yyyy")}`}
          </Typography>
          <Button
            disabled={
              (authInfo ? stepsOverview.loading : false) ||
              Boolean(jobApplicant)
            }
            size="sm"
            variant="outlined"
            onClick={handleApplyForJob}
          >
            {jobApplicant ? "Applied" : "Apply"}
          </Button>
        </div>
      </div>
    </>
  )
}

export default JobCard
