import React, { useEffect, useState } from "react"

import { CircularProgress, Select } from "@suraasa/placebo-ui"
import { Controller, useForm } from "react-hook-form"

import api from "api"
import { State } from "api/resources/global/types"
import useResources from "utils/hooks/useResources"

const LocationFilter = ({
  value: val,
  onChange: onSet,
}: {
  value: {
    country: string
    state: string | null
  }
  onChange?: ({
    country,
    state,
  }: {
    country: string
    state: string | null
  }) => void
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ country: string; state: string }>()

  const { countries } = useResources(["countries"])

  const [showStateField, setShowStateField] = useState(false)
  const [states, setStates] = useState<State[]>([])
  const [isFetchingStates, setIsFetchingStates] = useState(false)
  const country = watch("country")

  useEffect(() => {
    setValue("country", val.country)
    setValue("state", val.state || "")
  }, [val, setValue])

  useEffect(() => {
    if (country && !showStateField) setShowStateField(true)
    else setValue("state", "")
  }, [country, showStateField, setValue])

  useEffect(() => {
    const fetchStates = async () => {
      if (country) {
        setIsFetchingStates(true)

        const res = await api.global.states.list({
          urlParams: { country },
          params: { page: -1 },
        })

        if (res.isSuccessful) setStates(res.data)

        setIsFetchingStates(false)
      } else {
        setStates([])
      }
    }

    fetchStates()
  }, [country])

  if (countries.length === 0)
    return (
      <div className="flex justify-center">
        <CircularProgress />
      </div>
    )

  return (
    <>
      <Controller
        control={control}
        name="country"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            className="px-0.5 py-0.5"
            error={Boolean(errors.country)}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.uuid}
            helperText={errors.country?.message}
            label="Country"
            options={countries}
            placeholder="Ex: India"
            value={value ? countries.find(i => i.uuid === value) : null}
            fullWidth
            isClearable
            isSearchable
            mountOnBody
            onBlur={onBlur}
            onChange={v => {
              onChange(v?.uuid ? v.uuid : null)
              if (onSet) onSet({ country: v?.uuid || "", state: null })
            }}
          />
        )}
      />

      {showStateField && (
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              className="mt-1 px-0.5 py-0.5"
              error={Boolean(errors.state)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.uuid}
              helperText={errors.state?.message}
              isLoading={isFetchingStates}
              label="State"
              options={states}
              placeholder={states.length > 0 && `Ex: ${states[0].name}`}
              value={value ? states.find(i => i.uuid === value) : null}
              fullWidth
              isClearable
              isSearchable
              mountOnBody
              onBlur={onBlur}
              onChange={v => {
                onChange(v?.uuid ? v.uuid : null)
                if (onSet) onSet({ country, state: v?.uuid || "" })
              }}
            />
          )}
        />
      )}
    </>
  )
}

export default LocationFilter
