import React from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { createUseStyles } from "react-jss"
import { Link, useSearchParams } from "react-router-dom"

import { ClockOutline, Flare } from "iconoir-react"

import { JobOfferDetailsType } from "api/resources/jobs/types"
import ListItem from "components/jobs/ListItem"
import TimelineContent from "components/Timeline/TimelineContent"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.success[50],
    borderColor: theme.colors.success[500],
    zIndex: 1,
  },
  container: {
    width: "100%",
  },
  icon: {
    background: theme.colors.success[500],
    height: 32,
    width: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "& svg": {
      color: "white",
      fill: "white",
    },
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 20,
    },
  },
}))

const JobOfferTimelineItem = ({
  jobOffer,
}: {
  jobOffer: JobOfferDetailsType
}) => {
  const classes = useStyles()
  const [searchParams] = useSearchParams()
  const jobOfferId = searchParams.get("jobOfferId")

  return (
    <TimelineContent
      className={classes.root}
      icon={
        <span className={classes.icon}>
          <Flare height={18} width={18} />
        </span>
      }
    >
      <div
        className={clsx(
          classes.container,
          "flex justify-between gap-2 flex-wrap md:items-center flex-col md:flex-row"
        )}
      >
        <div className="flex flex-col gap-0.5 flex-1">
          <Typography color="onSurface.800" variant="strong">
            Job Offer Received
          </Typography>
          {jobOffer?.dateCreated && (
            <ListItem icon={<ClockOutline />}>
              <Typography
                color="onSurface.400"
                display="inline"
                variant="smallBody"
              >
                Received on{" "}
              </Typography>
              <Typography display="inline" variant="strongSmallBody">
                {format(
                  new Date(jobOffer?.dateCreated),
                  "dd MMMM yyyy',' hh:mm aa"
                )}
              </Typography>
            </ListItem>
          )}
        </div>
        {jobOfferId && (
          <Button
            color="success"
            component={Link}
            size="sm"
            to={routes.viewOffer.replace(":jobOfferId", jobOfferId)}
          >
            View Job Offer
          </Button>
        )}
      </div>
    </TimelineContent>
  )
}

export default JobOfferTimelineItem
