import React, { useContext, useEffect, useState } from "react"

import {
  Button,
  CircularProgress,
  Pagination,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

import api from "api"
import { Application } from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import NoDataCard from "components/shared/NoDataCard"
import SchoolLogo from "components/shared/SchoolLogo"
import SharedDialog from "components/SharedDialog"
import { pluralize } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { TabContext } from "views/Home/TabContext"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.75fr 1fr 1fr 1fr",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))

const AppliedTab = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)

  const { setOverview } = useContext(TabContext)

  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [applicationToRevoke, setApplicationToRevoke] =
    useState<Application | null>(null)

  const [applications, setApplications] = useState<
    PaginatedResponse<Application[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  useEffect(() => {
    setLoading(true)
    const listApplicants = async () => {
      const res = await api.jobs.jobApplicant.list({
        params: {
          page,
          application_type: "applied",
          ordering: "-application_sent_on",
          status: "applied",
        },
      })
      if (res.isSuccessful) {
        setApplications(res.data)
        setLoading(false)
      }
    }

    listApplicants()
  }, [page])

  const withdrawApplication = async () => {
    if (!applicationToRevoke) return

    setWithdrawLoading(true)

    const res = await api.jobs.jobApplicant.delete({
      urlParams: {
        jobApplicantId: applicationToRevoke.id,
      },
    })

    if (res.isSuccessful) {
      setApplicationToRevoke(null)
      setApplications(item => ({
        ...item,
        data: item.data.filter(v => v.id !== applicationToRevoke?.id),
      }))
      setOverview(prevState => ({
        ...prevState,
        totalApplied: prevState.totalApplied - 1,
      }))
      toast.success("Application withdrawn")
    } else {
      toast.error(res.errors.message)
    }
    setWithdrawLoading(false)
  }

  if (loading) {
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    )
  }

  if (applications.data.length === 0) {
    return (
      <NoDataCard
        className="mt-3"
        message="You have not applied in any schools. Check out the 'Explore' page to browse the list of schools"
      />
    )
  }

  return (
    <>
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-2")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>

            <Typography variant="strongSmallBody">Date Applied</Typography>
          </div>

          {applications.data.map((item, index) => (
            <div
              className={clsx(classes.tableGrid, classes.tableItem, "py-2")}
              key={index}
            >
              <div className="pl-3 mt-0.5">
                <SchoolLogo className={classes.image} src={item.school.logo} />
              </div>

              <div>
                <div className="flex items-center gap-0.5">
                  <Button
                    className="-ml-0.75"
                    component={Link}
                    to={`${routes.schoolProfile.replace(
                      ":slug",
                      item.school.slug
                    )}?jobId=${item.job.id}`}
                    variant="link"
                  >
                    <Typography
                      color="interactive.600"
                      display="inline"
                      variant="smallBody"
                    >
                      {item.school.name}
                    </Typography>
                  </Button>
                </div>
                {item.school.state?.name && item.school.country?.name && (
                  <Typography
                    className={clsx(classes.textSmall, "mb-0.5")}
                    color="onSurface.500"
                  >
                    {item.school.state.name}, {item.school.country.name}
                  </Typography>
                )}

                <Typography variant="smallBody">{item.job.position}</Typography>

                <Typography variant="smallBody">
                  {item.job.subject.name}
                </Typography>
              </div>

              <div className="flex flex-col justify-center">
                <Typography variant="smallBody">
                  {format(new Date(item.dateApplied), "d MMM yyyy")}
                </Typography>
              </div>

              <div className="flex flex-col justify-center">
                {item.applicants > 0 && (
                  <Typography variant="smallBody">
                    {pluralize("Applicant", item.applicants)}
                  </Typography>
                )}
              </div>
              <div className="flex flex-col items-end justify-center mr-2">
                <Button
                  color="critical"
                  variant="text"
                  onClick={() => {
                    setApplicationToRevoke(item)
                  }}
                >
                  Withdraw Application
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {applications.total > 10 && (
        <Pagination page={page} total={applications.total} onChange={setPage} />
      )}

      {applicationToRevoke && (
        <SharedDialog
          actionLabel="Withdraw"
          handleClose={() => setApplicationToRevoke(null)}
          loading={withdrawLoading}
          open={Boolean(applicationToRevoke)}
          title="Withdraw Application"
          isDestructive
          onConfirm={withdrawApplication}
        >
          <Typography variant="body">
            Are you sure you want to withdraw your application from{" "}
            <b>{applicationToRevoke.school.name}’s</b> job opening for&nbsp;
            <b>
              {applicationToRevoke.job.position},{" "}
              {applicationToRevoke.job.subject.name}
            </b>
            ?
          </Typography>
        </SharedDialog>
      )}
    </>
  )
}
export default AppliedTab
