import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
  URLParams,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import { GalleryItem, School, SchoolProfile } from "./types"
import { urls } from "./urls"

export default {
  list: async ({ params }: Config): Promise<PaginatedAPIResponse<School[]>> => {
    try {
      const res = await axios.get(urls.profile.update(), {
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<SchoolProfile>> => {
    try {
      const res = await axios.get(urls.profile.retrieve(urlParams.slug), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  gallery: {
    list: async ({
      urlParams,
    }: URLParams<"slug">): Promise<APIResponse<GalleryItem[]>> => {
      try {
        const res = await axios.get(urls.gallery.list(urlParams.slug))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interestedUser: {
    create: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(
          urls.interestedUser.create(urlParams.schoolId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
