import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  assessment: {
    start: jobApplicantStepId =>
      getNebulaURL(`/v1/assessments/start/${jobApplicantStepId}/`),
    submit: jobApplicantStepId =>
      getNebulaURL(`/v1/assessments/submit/${jobApplicantStepId}/`),
    listQuestions: jobApplicantStepId =>
      getNebulaURL(`/v1/assessments/steps/${jobApplicantStepId}/questions/`),
    markAnswer: (jobApplicantStepId, questionId) =>
      getNebulaURL(
        `/v1/assessments/mark-answer/${jobApplicantStepId}/${questionId}/`
      ),
  },
})
