import React, { useCallback, useEffect, useState } from "react"

import { CircularProgress, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { createUseStyles } from "react-jss"
import { useNavigate, useParams } from "react-router"

import api from "api"
import { Interview } from "api/resources/interviews/types"
import Navbar from "components/shared/Navbar"
import { generateHuddleURL } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  root: {
    border: `1px solid ${theme.colors.surface[200]}`,
    backgroundColor: theme.colors.surface[500],
    borderRadius: "4px",
  },
}))

const Wrapper: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <Navbar />
      <Container>
        <div
          className={clsx(
            "px-10 py-7 flex flex-col items-center",
            classes.root
          )}
        >
          {children}
        </div>
      </Container>
    </>
  )
}

const WaitingForHost = () => {
  const { interviewId } = useParams()
  const navigate = useNavigate()

  const [interview, setInterview] = useState<Interview | null>(null)
  const [keepPolling, setKeepPolling] = useState(true)

  const getInterview = useCallback(async () => {
    if (!interviewId) return
    const res = await api.interviews.retrieve({
      urlParams: {
        interviewId,
      },
    })

    if (res.isSuccessful) {
      setInterview(res.data)

      if (res.data.videoMeeting && res.data.videoMeeting.meetingUrl) {
        navigate(
          generateHuddleURL({
            meetingUrl: res.data.videoMeeting.meetingUrl,
          }),
          { replace: true }
        )
      }

      // meetingUrl=null & meetingEndTime=string. That means meeting has ended.
      if (res.data.videoMeeting?.meetingEndTime) {
        setKeepPolling(false)
      }
    }
    // Can't add navigate as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId])

  useEffect(() => {
    getInterview()

    const interval = setInterval(getInterview, 10000)

    if (!keepPolling) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [getInterview, keepPolling])

  if (!interview)
    return (
      <Wrapper>
        <CircularProgress />
      </Wrapper>
    )

  if (interview.videoMeeting?.meetingEndTime)
    return (
      <Wrapper>
        <div className="mb-4 flex items-center gap-6">
          <Typography variant="title1">This meeting has ended</Typography>
        </div>
        <Typography className="mb-1" variant="strong">
          {interview.name}
        </Typography>
      </Wrapper>
    )

  return (
    <Wrapper>
      <div className="mb-4 flex flex-col items-center gap-6">
        <CircularProgress size="lg" />
        <Typography textAlign="center" variant="title1">
          Waiting for host to start this meeting
        </Typography>
      </div>
      <Typography className="mb-1" variant="strong">
        {interview.name}
      </Typography>
      <Typography variant="body">
        Starts at {format(new Date(interview.scheduledTime), "h:mm a")}
      </Typography>
    </Wrapper>
  )
}

export default WaitingForHost
