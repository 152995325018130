import React from "react"

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
} from "@suraasa/placebo-ui"
import { useForm } from "react-hook-form"
import isURL from "validator/es/lib/isURL"

type Props = Pick<DialogProps, "open"> & {
  toggle: () => void
  onAdd: (url: string) => void
}

const UploadCertificationUrlDialog = ({ toggle, open, onAdd }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<{ url: string }>()

  const onSubmit = handleSubmit(formData => {
    onAdd(formData.url)
    toggle()
  })

  return (
    <Dialog
      open={open}
      keepScrollLocked
      onAfterClose={() => reset({})}
      onRequestClose={toggle}
    >
      <DialogTitle onBack={toggle}>Add Certificate Link</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <TextField
            label="Certificate LINK/URL"
            placeholder="Enter the link to the certificate"
            autoFocus
            fullWidth
            {...register("url", {
              validate: value =>
                isURL(value, {
                  protocols: ["https", "http"],
                }) || "Invalid URL",
            })}
            error={Boolean(errors.url)}
            helperText={errors.url?.message}
          />
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Add Link",
            type: "submit",
            loading: isSubmitting,
            onClick: onSubmit,
          },
        }}
      />
    </Dialog>
  )
}

export default UploadCertificationUrlDialog
