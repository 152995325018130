import React, { useState } from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

// Ref: https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
function fade(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

const useStyles = createUseStyles(theme => ({
  "@keyframes flicker": {
    "0%": {
      backgroundColor: "#fff",
    },
    "25%": {
      backgroundColor: fade(theme.colors.primary[50], 0.75),
    },
    "50%": {
      backgroundColor: "#fff",
    },
    "75%": {
      backgroundColor: fade(theme.colors.primary[50], 0.75),
    },
    "100%": {
      backgroundColor: "#fff",
    },
  },

  button: {
    color: "#000",
    display: "flex",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(5 / 8),
    border: `2px solid ${theme.colors.onSurface[200]}`,
    width: "100%",
    backgroundColor: "#fff",
    "&:hover": {
      border: `2px solid ${theme.colors.onSurface[200]}`,
      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
      cursor: "pointer",
    },
    "& *": {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      "& *": {
        fontSize: 14,
      },
    },
  },

  selected: {
    border: `2px solid ${theme.colors.primary[500]} !important`,

    "&.animate": {
      animation: "$flicker 0.5s linear",
    },
  },
}))

const SelectableCard = ({
  selected,
  className,
  children,
  onClick,
  disabled = false,
}: {
  /**
   * Use this className to change card appearance.
   */
  className?: string
  selected: boolean
  disabled?: boolean
  children: React.ReactNode

  onClick: () => void
}) => {
  const classes = useStyles()

  const [animate, setAnimate] = useState(!selected)

  return (
    <button
      className={clsx(classes.button, className, {
        [classes.selected]: selected,
        animate,
      })}
      disabled={disabled}
      type="button"
      onClick={() => {
        setAnimate(true)
        onClick()
      }}
    >
      {children}
    </button>
  )
}

export default SelectableCard
