import React, { useContext } from "react"

import { Divider, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format, parse } from "date-fns"
import { createUseStyles } from "react-jss"

import { TeachingMode } from "api/resources/schools/types"
import TruncatedText from "components/shared/TruncatedText"
import { tabsProfile } from "utils/constants"
import SchoolProfileContext from "views/schoolProfile/context"

import WorkingDays from "./WorkingDays"

const useStyles = createUseStyles(theme => ({
  container: () => ({
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(0, 0, 1.5, 1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
    [theme.breakpoints.down("xs")]: {
      borderRadius: theme.spacing(0),
    },
  }),
  managementImageContainer: {
    height: "120px",
    width: "120px",
    [theme.breakpoints.down("xs")]: {
      height: "57px",
      width: "57px",
    },
  },
  managementImage: {
    height: "inherit",
    width: "inherit",
    objectFit: "cover",
    objectPosition: "top",
    borderRadius: "100px",
  },

  roundedDay: {
    borderRadius: "50px",
    width: "35px",
    padding: "0",
    display: "grid",
    placeItems: "center",
    height: "35px",
    color: theme.colors.onSurface[500],
    background: theme.colors.surface[200],
  },

  selectedDay: {
    color: "white",
    background: theme.colors.primary[500],
  },

  teachingModeDot: {
    width: "8px",
    height: "8px",
    borderRadius: "50px",
    overflow: "hidden",
  },

  onlineDot: {
    background: theme.colors.success[500],
  },

  offlineDot: {
    background: theme.colors.secondary[200],
  },

  blendedCircle1: {
    height: "8px",
    width: "4px",
    background: theme.colors.success[500],
  },
  blendedCircle2: {
    height: "8px",
    width: "4px",
    background: theme.colors.secondary[200],
  },
  teachingModeTag: {
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "min-content",
  },
}))

const getTeachingModeDisplay = (mode: TeachingMode) => {
  switch (mode) {
    case TeachingMode.ONLINE:
      return "Online"
    case TeachingMode.OFFLINE:
      return "Offline"
    case TeachingMode.HYBRID:
      return "Blended"

    default:
      return ""
  }
}

const ReturnDot = (teachingStatus: TeachingMode) => {
  const classes = useStyles()

  if (teachingStatus === TeachingMode.HYBRID) {
    return (
      <div className={clsx("flex", classes.teachingModeDot)}>
        <div className={classes.blendedCircle1} />
        <div className={classes.blendedCircle2} />
      </div>
    )
  }

  if (teachingStatus === TeachingMode.ONLINE) {
    return <div className={clsx(classes.teachingModeDot, classes.onlineDot)} />
  }

  if (teachingStatus === TeachingMode.OFFLINE) {
    return <div className={clsx(classes.teachingModeDot, classes.offlineDot)} />
  }
  return "-"
}

function Overview() {
  const classes = useStyles()

  const { profile } = useContext(SchoolProfileContext)

  // const startDay = weekDays.find(
  //   item => profile.workingDays && item.id === profile.workingDays[0]
  // )?.day

  // const endDay = weekDays.find(
  //   item =>
  //     profile.workingDays &&
  //     item.id === profile.workingDays[profile.workingDays.length - 1]
  // )?.day

  return (
    <div
      className={clsx("px-2 py-3.5 sm:px-5 sm:py-4.5", classes.container)}
      id={tabsProfile.overview}
    >
      <div className="flex items-center justify-between">
        <Typography color="primary.500" variant="preTitle">
          About
        </Typography>
      </div>

      {profile.description ? (
        <TruncatedText
          className="mt-1.5 mb-5"
          maxLength={500}
          variant="largeBody"
        >
          {profile.description}
        </TruncatedText>
      ) : (
        <Typography
          className="mt-1.5 mb-3.5"
          color="onSurface.400"
          variant="smallBody"
        >
          Looks like there's nothing here
        </Typography>
      )}

      <Divider className="my-0.5" color="onSurface.200" />
      <div className="flex items-center justify-between">
        <Typography
          className="my-2.5 sm:mt-5 sm:mb-0"
          color="primary.500"
          variant="preTitle"
        >
          Management’s Message
        </Typography>
      </div>

      {profile.managementMessage ? (
        <>
          <div className="hidden sm:flex gap-3 mt-2.5 mb-5">
            {profile.managementMessage.image && (
              <div className={classes.managementImageContainer}>
                <img
                  alt="display"
                  className={classes.managementImage}
                  src={
                    profile.managementMessage
                      ? profile.managementMessage.image
                      : ""
                  }
                />
              </div>
            )}
            <div>
              <TruncatedText maxLength={500} variant="body">
                {profile.managementMessage
                  ? profile.managementMessage.message
                  : ""}
              </TruncatedText>

              <Typography className="mt-1.5" variant="emphasised">
                {profile.managementMessage
                  ? profile.managementMessage.name
                  : ""}
              </Typography>

              <Typography color="onSurface.500" variant="smallBody">
                {profile.managementMessage
                  ? profile.managementMessage.position
                  : ""}
              </Typography>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-2.5 mb-5 sm:hidden">
            <TruncatedText maxLength={500} variant="body">
              {profile.managementMessage
                ? profile.managementMessage.message
                : ""}
            </TruncatedText>

            <div className="flex gap-3">
              {profile.managementMessage.image && (
                <div className={classes.managementImageContainer}>
                  <img
                    alt="display"
                    className={classes.managementImage}
                    src={
                      profile.managementMessage
                        ? profile.managementMessage.image
                        : ""
                    }
                  />
                </div>
              )}
              <div>
                <Typography className="mt-1.5" variant="emphasised">
                  {profile.managementMessage
                    ? profile.managementMessage.name
                    : ""}
                </Typography>

                <Typography color="onSurface.500" variant="smallBody">
                  {profile.managementMessage
                    ? profile.managementMessage.position
                    : ""}
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Typography
          className="mt-2.5 mb-3.5"
          color="onSurface.400"
          variant="smallBody"
        >
          Looks like there's nothing here
        </Typography>
      )}

      {profile.dayStartTime && profile.workingDays && (
        <>
          <Divider color="onSurface.200" />

          <Typography
            className="mt-3.5 mb-0.25"
            color="primary.500"
            variant="preTitle"
          >
            Additional Information
          </Typography>

          <Typography className="mt-3.5 mb-1" variant="strong">
            Work Days & Hours
          </Typography>

          <div className="flex-col-reverse justify-between mb-3.5 gap-2 flex items-stretch sm:flex-row sm:items-center sm:gap-0 pt-0 ">
            <Typography className="mt-1.75 " variant="body">
              {/* {startDay} - {endDay},{" "} */}
              {profile.workingDays.length} days a week •{" "}
              {format(
                parse(profile.dayStartTime, "H:mm:ss", new Date()),
                "h:mm a"
              )}{" "}
              -{" "}
              {format(
                parse(profile.dayEndTime, "H:mm:ss", new Date()),
                "h:mm a"
              )}
            </Typography>
            <WorkingDays workingDays={profile.workingDays} disabled />
          </div>

          <Typography className="mt-0.75 mb-1.5" variant="strong">
            Teaching Mode
          </Typography>

          {profile.teachingMode ? (
            <div
              className={clsx(
                "flex items-center gap-1 px-1.5 py-0.5 mb-1",
                classes.teachingModeTag
              )}
            >
              {ReturnDot(profile.teachingMode)}
              <Typography variant="button">
                {getTeachingModeDisplay(profile.teachingMode)}
              </Typography>
            </div>
          ) : (
            "-"
          )}
        </>
      )}
    </div>
  )
}

export default Overview
