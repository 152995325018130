import React, { useEffect, useState } from "react"

import {
  CircularProgress,
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"
import { useParams, useSearchParams } from "react-router-dom"

import api from "api"
import { Job, JobOfferDetailsType, Step } from "api/resources/jobs/types"
import JobPostCard from "components/jobs/JobPostCard"
import JobOfferTimelineItem from "components/jobs/Timeline/JobOfferTimelineItem"
import TimelineItem from "components/jobs/Timeline/TimelineItem"
import SchoolProfile from "components/schoolProfile/SchoolProfile"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import Navbar from "components/shared/Navbar"
import TabItem from "components/Tab/TabItem"
import TimelineComponent from "components/Timeline"
import useTabs, { Tabs } from "utils/hooks/useTabs"

const useStyles = createUseStyles(theme => ({
  tab: {
    overflow: "auto",
    "& *": {
      flexShrink: 0,
    },
  },

  profileContainer: {
    borderBottom: `2px solid ${theme.colors.onSurface[200]}`,
  },
}))

const Timeline = () => {
  const classes = useStyles()
  const { jobId, jobApplicant } = useParams()
  const theme = useTheme<Theme>()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const [job, setJob] = useState<Job>()
  const [jobOffer, setJobOffer] = useState<JobOfferDetailsType>()
  const [timeline, setTimeline] = useState<Step[]>([])

  const tabs: Tabs = [
    {
      content: (
        <TimelineComponent steps={timeline}>
          {timeline.length === 0 ? (
            <Typography>Awaiting action from recruiter</Typography>
          ) : (
            <>
              {jobOffer && <JobOfferTimelineItem jobOffer={jobOffer} />}
              {timeline.map(step => (
                <TimelineItem {...step} key={step.id} />
              ))}
            </>
          )}
        </TimelineComponent>
      ),
      name: "Timeline",
    },
    {
      content: <SchoolProfile slug={job?.school.slug || ""} />,
      name: "Profile",
    },
  ]

  const [activeTab, setActiveTab] = useTabs({
    initialTab: tabs[0].tab,
    tabs,
  })

  useEffect(() => {
    const listSteps = () =>
      api.jobs.jobApplicantStep.list({
        urlParams: { jobApplicantId: jobApplicant as string },
      })
    const retrieveJob = () => {
      if (jobId)
        return api.jobs.retrieve({
          urlParams: { jobId },
        })
    }
    const getJobOffer = () => {
      const jobOfferId = searchParams.get("jobOfferId")
      if (jobOfferId) {
        return api.jobs.jobOffers.retrieve({
          urlParams: { jobOfferId },
        })
      }
    }

    const fetchData = async () => {
      if (!jobId) return
      setLoading(true)
      setError("")

      const [jobRes, timelineRes, jobOfferRes] = await Promise.all([
        retrieveJob(),
        listSteps(),
        getJobOffer(),
      ])

      if (jobRes) {
        if (jobRes.isSuccessful) {
          setJob(jobRes.data)
        }
      }

      if (timelineRes.isSuccessful) {
        setTimeline(timelineRes.data)
      } else {
        setError(timelineRes.errors.message ?? "Something went wrong")
      }

      if (jobOfferRes) {
        if (jobOfferRes.isSuccessful) {
          setJobOffer(jobOfferRes.data)
        } else {
          setError(jobOfferRes.errors.message ?? "Something went wrong")
        }
      }

      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, jobApplicant])

  if (loading) {
    return (
      <div className="flex items-center justify-center p-3">
        <CircularProgress />
      </div>
    )
  }

  if (error) {
    return (
      <Section className="m-3">
        <SectionContent className="flex items-center justify-center p-3">
          <Typography className="title3">{error}</Typography>
        </SectionContent>
      </Section>
    )
  }

  if (activeTab.name === "Profile") {
    return (
      <>
        <Navbar />
        <div className="mb-3">
          <Container>
            {job && <JobPostCard job={job} />}
            <div className={clsx("my-6", classes.profileContainer)}>
              <div className="flex -mb-0.25">
                {tabs.map(tab => (
                  <TabItem
                    isActive={tab.name === activeTab?.name}
                    key={tab.name}
                    onClick={() => setActiveTab(tab.name)}
                  >
                    {tab.name}
                  </TabItem>
                ))}
              </div>
            </div>
          </Container>

          <div>{activeTab && activeTab.content}</div>
        </div>
      </>
    )
    // return activeTab.content
  }

  return (
    <>
      <Navbar />
      <Container className="mb-3" fullWidth={xsDown}>
        {job && <JobPostCard job={job} />}
        <div className={clsx("my-6", classes.profileContainer)}>
          <div className="flex mx-2 sm:mx-0 -mb-0.25">
            {tabs.map(tab => (
              <TabItem
                isActive={tab.name === activeTab?.name}
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name}
              </TabItem>
            ))}
          </div>
        </div>

        <Section>
          <SectionContent>{activeTab && activeTab.content}</SectionContent>
        </Section>
      </Container>
    </>
  )
}

export default Timeline
