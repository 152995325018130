import React from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import { ReactComponent as Illustration } from "assets/illustrations/broke-connection.svg"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

import Navbar from "components/shared/Navbar"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "100%",
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const InvalidMeeting = () => {
  const classes = useStyles()

  return (
    <>
      <Navbar />
      <div
        className={clsx(
          "flex px-2 gap-2 flex-col-reverse justify-evenly items-center md:flex-row",
          classes.root
        )}
      >
        <div className="flex flex-col gap-2">
          <Typography color="onSurface.800" variant="largeTitle">
            Ooooops!
          </Typography>
          <Typography color="onSurface.500" variant="largeBody">
            The meeting link is invalid
          </Typography>
          <Typography color="onSurface.800" variant="largeBody">
            If the issue persists, please reach out to us.
          </Typography>
          <div className="flex gap-2 flex-col mt-3">
            <Button component={Link} size="sm" to={routes.home}>
              Return to Dashboard
            </Button>
            <Button variant="text">Contact us</Button>
          </div>
        </div>
        <Illustration className={classes.image} />
      </div>
    </>
  )
}

export default InvalidMeeting
