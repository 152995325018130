import React, { useContext, useEffect, useRef, useState } from "react"

import { NotificationsPopup } from "@suraasa/notifications"
import {
  Avatar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import { ReactComponent as MiniLogo } from "assets/logos/suraasa-colored-small.svg"
import { ReactComponent as Logo } from "assets/logos/suraasa-logo-new.svg"
import clsx from "clsx"
import { GlobalContext } from "GlobalState"
import { createUseStyles } from "react-jss"
import { Link, NavLink, useNavigate } from "react-router-dom"

import { LogOut, Menu as MenuIcon, NavArrowDown } from "iconoir-react"

import axios from "api/axios"
import ProfileNotCompletedBanner from "components/auth/ProfileNotCompletedBanner"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
import { getPlatformURL, notificationHelper } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"

import BackButton, { BackButtonProps } from "./BackButton"
import Sidebar from "./Sidebar"

const NAVBAR_HEIGHT = 62
const useStyles = createUseStyles(theme => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.navbar,
  },

  nav: {
    height: NAVBAR_HEIGHT,
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    backgroundColor: "white",
    position: "relative",
    zIndex: theme.zIndex.navbar,
    transition: "box-shadow 225ms ease-in-out",
  },

  gutterBottom: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(8),
    },
  },
  containerRoot: {
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  enableShadow: {
    boxShadow: "1px 1px 5px 0px #0000001A",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
}))

export type NavbarProps = {
  slotEnd?: React.ReactElement
  className?: string
  gutterBottom?: boolean
  hideBackButton?: boolean
  backButtonProps?: BackButtonProps
  showAuth?: boolean
  hideBanner?: boolean
}

const Navbar = ({
  slotEnd,
  gutterBottom = true,
  showAuth = true,
  hideBackButton = false,
  className,
  hideBanner = false,
  backButtonProps,
}: NavbarProps) => {
  const classes = useStyles()

  const authInfo = getAuthInfo()
  const { notifications } = useContext(GlobalContext)
  const navigate = useNavigate()

  const [enableShadow, setEnableShadow] = useState(false)

  const [sidebarOpen, toggleSidebar] = useToggle(false)
  const navigationRef = useRef<HTMLDivElement>(null)

  const keyDownHandler = (e: React.KeyboardEvent) => {
    // only execute if tab is pressed
    if (e.key !== "Tab") return
    if (navigationRef.current === null) return
    // here we query all focusable elements, customize as your own need
    const focusableModalElements = navigationRef.current.querySelectorAll(
      "a[href], button:not([disabled]), textarea, input, select"
    )

    const firstElement = focusableModalElements[0] as HTMLElement
    const lastElement = focusableModalElements[
      focusableModalElements.length - 1
    ] as HTMLElement

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    // if going backward by pressing tab and firstElement is active shift focus to last focusable element
    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    const handleShadow = () => {
      if (window.scrollY > NAVBAR_HEIGHT) {
        setEnableShadow(true)
      } else {
        setEnableShadow(false)
      }
    }
    window.addEventListener("scroll", handleShadow)
    return () => window.removeEventListener("scroll", handleShadow)
  })

  return (
    <>
      <div
        className={classes.root}
        ref={navigationRef}
        role="none"
        onKeyDown={sidebarOpen ? keyDownHandler : undefined}
      >
        <nav
          className={clsx(classes.nav, className, "py-1 flex items-center", {
            [classes.enableShadow]: enableShadow,
          })}
        >
          <Container className={classes.containerRoot}>
            <div className={clsx(classes.container)}>
              {/* Start */}
              <div className="flex items-center gap-2.5">
                {authInfo && (
                  <IconButton color="black" onClick={() => toggleSidebar()}>
                    <MenuIcon />
                  </IconButton>
                )}
                <a
                  className="md:hidden"
                  href={getPlatformURL("suraasa", "/jobs/teacher")}
                >
                  <MiniLogo height="40" width="50" />
                </a>
                <a
                  className="hidden md:block"
                  href={getPlatformURL("suraasa", "/jobs/teacher")}
                >
                  <Logo width="150px" />
                </a>
                <Menu
                  menuButton={
                    <Button
                      color="black"
                      endAdornment={<NavArrowDown />}
                      variant="text"
                    >
                      Jobs
                    </Button>
                  }
                >
                  <MenuItem color="primary">Jobs</MenuItem>
                  <MenuItem
                    onClick={() =>
                      (window.location.href = getPlatformURL(
                        "learn",
                        "/dashboard/library"
                      ))
                    }
                  >
                    Learn
                  </MenuItem>
                </Menu>
              </div>

              {/* End */}
              <div className="flex gap-1">
                {slotEnd && <div>{slotEnd}</div>}
                {showAuth &&
                  (authInfo ? (
                    <div className="flex items-center gap-1.5">
                      <div className="items-center hidden gap-3 mr-2 md:flex">
                        <NavLink className={classes.link} to={routes.explore}>
                          <Typography variant="strong">Explore Jobs</Typography>
                        </NavLink>

                        <NavLink className={classes.link} to={routes.home}>
                          <Typography variant="strong">Dashboard</Typography>
                        </NavLink>

                        <a
                          className={classes.link}
                          href={getPlatformURL("learn", "/dashboard/library")}
                        >
                          <Typography variant="strong">My library</Typography>
                        </a>
                      </div>

                      <NotificationsPopup
                        axiosInstance={axios}
                        notifications={notifications}
                        onClick={notificationItem =>
                          notificationHelper(navigate, notificationItem.action)
                        }
                      />

                      <div>
                        <Menu
                          align="end"
                          menuButton={
                            <IconButton size="md">
                              <Avatar
                                color="two"
                                name={`${authInfo.user.firstName} ${authInfo.user.lastName}`}
                                src={authInfo.user.photo ?? undefined}
                              />
                            </IconButton>
                          }
                        >
                          <MenuItem
                            onClick={() => {
                              window.location.href = getPlatformURL(
                                "sso",
                                "/dashboard/profile"
                              )
                            }}
                          >
                            Profile
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              window.location.href = getPlatformURL(
                                "learn",
                                "/dashboard/bookmarks"
                              )
                            }}
                          >
                            Bookmarks
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              window.location.href = getPlatformURL(
                                "learn",
                                "/dashboard/orders"
                              )
                            }}
                          >
                            Orders
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              window.location.href = getPlatformURL(
                                "learn",
                                "/dashboard/feedback"
                              )
                            }}
                          >
                            Give Feedback
                          </MenuItem>
                          <MenuItem
                            color="critical"
                            startAdornment={<LogOut />}
                            onClick={() => {
                              clearAuthInfo()
                              const url = new URL(
                                `${process.env.REACT_APP_SSO_URL}/logout`
                              )
                              url.searchParams.append(
                                "origin",
                                `${window.location.origin}`
                              )
                              url.searchParams.append("platform", USER_TYPE)
                              window.location.href = url.href
                            }}
                          >
                            Logout
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <Typography>
                      Already have an account?&nbsp;
                      <Button
                        component={Link}
                        to={{
                          search: `?next=${window.location.pathname}`,
                          pathname: routes.sso,
                        }}
                        variant="link"
                      >
                        Sign In
                      </Button>
                    </Typography>
                  ))}
              </div>
            </div>
          </Container>
        </nav>
        {authInfo && <Sidebar open={sidebarOpen} toggle={toggleSidebar} />}
      </div>
      {!hideBanner && <ProfileNotCompletedBanner />}
      {!hideBackButton && (
        <div className="my-2">
          <Container className={classes.containerRoot}>
            <BackButton {...backButtonProps} />
          </Container>
        </div>
      )}
      <div className={clsx({ [classes.gutterBottom]: gutterBottom })} />
    </>
  )
}

export default Navbar
