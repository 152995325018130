import { Tag } from "@suraasa/placebo-ui"
import { ReactComponent as Completed } from "assets/stepper/completed.svg"
import {
  ReactComponent as InProgress,
  ReactComponent as Skipped,
} from "assets/stepper/in-progress.svg"
import { ReactComponent as Missed } from "assets/stepper/missed.svg"
import { ReactComponent as NotStarted } from "assets/stepper/not-completed.svg"
import { add } from "date-fns"

import {
  JobApplicantStepStatus,
  Step,
  StepType,
} from "api/resources/jobs/types"

const isMissed = (step: Step) => {
  switch (step.stepType) {
    case StepType.ASSESSMENT:
      return new Date() > new Date(step.dueDate)
    case StepType.INTERVIEW:
    case StepType.LIVE_DEMO:
      return (
        new Date() >
        add(new Date(step.dueDate), { minutes: step.step.duration })
      )

    default:
      return false
  }
}

export const getIcon = (step: Step) => {
  switch (step.status) {
    case JobApplicantStepStatus.NOT_STARTED: {
      if (isMissed(step)) return <Missed />
      return <NotStarted />
    }
    case JobApplicantStepStatus.IN_PROGRESS:
      return <InProgress />
    case JobApplicantStepStatus.COMPLETED:
      return <Completed />
    case JobApplicantStepStatus.SKIPPED:
      return <Skipped />
    default:
      break
  }
}

export const getTag = (step: Step) => {
  switch (step.status) {
    case JobApplicantStepStatus.NOT_STARTED: {
      if (isMissed(step)) return <Tag color="critical" label="Missed" />
      return <Tag color="warning" label="Not Started" />
    }

    case JobApplicantStepStatus.IN_PROGRESS:
      return <Tag color="primary" label="In Progress" />
    case JobApplicantStepStatus.COMPLETED:
      return <Tag color="success" label="Completed" />
    case JobApplicantStepStatus.SKIPPED:
      return <Tag color="primary" label="Skipped" />
    default:
      break
  }
}
