import { useContext } from "react"

import { GlobalContext } from "GlobalState"
import { useNavigate } from "react-router-dom"

import ProfileCompletion from "components/ProfileCompletion"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { isProfileComplete } from "utils/helpers"
import { routes } from "utils/routes"

const CompleteProfilePage = () => {
  const { stepsOverview } = useContext(GlobalContext)
  const navigate = useNavigate()

  if (stepsOverview.loading) return <LoadingOverlay showLogo />
  if (
    isProfileComplete(stepsOverview) &&
    stepsOverview._extra?.autoRedirect === true
  ) {
    navigate(routes.home, { replace: true })
  }
  return <ProfileCompletion open />
}

export default CompleteProfilePage
