import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getGravityURL = getServiceURL(ServicePrefix.gravity)

export const urls = validateUrls({
  subject: {
    list: () => getGravityURL("/subjects"),
  },
  curricula: {
    list: () => getGravityURL("/curricula"),
  },
  countries: {
    list: () => getGravityURL("/countries"),
  },
  states: {
    list: countryId => getGravityURL(`/countries/${countryId}/states`),
  },

  qualificationFields: {
    list: () => getGravityURL("/qualification-fields"),
    create: () => getGravityURL("/qualification-fields/create"),
  },
})
