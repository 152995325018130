import { useEffect, useState } from "react"

import api from "api"
import { Country, Curriculum, Subject } from "api/resources/global/types"

type Resources = "subjects" | "countries" | "curricula"

type Props = Resources[]

/**
 *
 * @param resource
 * @returns values of resources passed,
 * returns empty array if a specific resource is not passed
 */
const useResources = (resource: Props) => {
  const [subjects, setSubjects] = useState<Subject[]>([])
  const [countries, setCountries] = useState<Country[]>([])

  const [curricula, setCurricula] = useState<Curriculum[]>([])

  useEffect(() => {
    const fetchResources = async () => {
      if (resource.includes("countries")) {
        const listCountries = await api.global.countries.list({
          params: { page: -1 },
        })
        if (listCountries.isSuccessful) {
          setCountries(listCountries.data)
        }
      }

      if (resource.includes("subjects")) {
        const listSubjects = await api.global.subject.list({
          params: { page: -1 },
        })
        if (listSubjects.isSuccessful) {
          setSubjects(listSubjects.data)
        }
      }

      if (resource.includes("curricula")) {
        const listCurricula = await api.global.curricula.list({
          params: { page: -1 },
        })
        if (listCurricula.isSuccessful) {
          setCurricula(listCurricula.data)
        }
      }
    }
    fetchResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    subjects,
    countries,
    curricula,
  }
}

export default useResources
