import React from "react"

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
} from "@suraasa/placebo-ui"

import { ToggleValue } from "utils/hooks/useToggle"

type Props = {
  toggle: ToggleValue
  handleReject: () => void
} & Pick<DialogProps, "open">

const RejectOfferDialog = ({ open, toggle, handleReject }: Props) => (
  <Dialog open={open} width={358} onRequestClose={() => toggle()}>
    <DialogTitle>Reject Offer</DialogTitle>
    <DialogContent>
      Are you sure you want to reject this Job Offer? This action is
      irreversible.
    </DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: "Reject Offer",
          color: "critical",
          onClick: handleReject,
        },
      }}
    />
  </Dialog>
)

export default RejectOfferDialog
