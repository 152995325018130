import React from "react"

import { Error, ErrorType } from "@suraasa/error-pages"
import { useNavigate } from "react-router-dom"

import Navbar from "components/shared/Navbar"
import { getPlatformURL } from "utils/helpers"

const ErrorPage = ({ errorCode }: Pick<ErrorType, "errorCode">) => {
  const router = useNavigate()

  switch (errorCode) {
    case 401:
      return (
        <>
          <title>Suraasa | 401</title>

          <Error
            errorCode={401}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onLogin: () =>
                (window.location.href = getPlatformURL("sso", "/")),
            }}
          />
        </>
      )
    case 403:
      return (
        <>
          <title>Suraasa | 403</title>

          <Error
            errorCode={403}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onHomepage: () => router("/"),
              onHelp: () =>
                (window.location.href = getPlatformURL("sso", "/help")),
            }}
          />
        </>
      )
    case 404:
      return (
        <>
          <title>Suraasa | 404</title>
          <Error
            errorCode={404}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onHomepage: () => router("/"),
              onHelp: () =>
                (window.location.href = getPlatformURL("sso", "/help")),
            }}
          />
        </>
      )
    case 500:
      return (
        <>
          <title>Suraasa | 500</title>
          <Error
            errorCode={500}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onHelp: () =>
                (window.location.href = getPlatformURL("sso", "/help")),
            }}
          />
        </>
      )
    default:
      return null
  }
}

export default ErrorPage
