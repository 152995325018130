import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  interview: {
    retrieve: interviewId => getNebulaURL(`/v1/interviews/${interviewId}/`),
  },
})
