import React from "react"

import { Button, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

import Navbar from "components/shared/Navbar"

const useStyles = createUseStyles(theme => ({
  card: {
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: "4px",
    background: theme.colors.surface[500],
  },
}))

const Logout = () => {
  const classes = useStyles()
  return (
    <div>
      <Navbar hideBackButton />
      <Container>
        <div className={clsx(classes.card, "px-5 py-4")}>
          <Typography className="mb-2" color="onSurface.700" variant="title2">
            You have been successfully logged out.
          </Typography>
          <Button component={Link} to="/" variant="text">
            Login Again
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default Logout
