import assessments from "./resources/assessments"
import enrollments from "./resources/enrollments"
import global from "./resources/global"
import interviews from "./resources/interviews"
import jobs from "./resources/jobs"
import profile from "./resources/profile"
import schools from "./resources/schools"
import users from "./resources/users"

export default {
  assessments,
  interviews,
  users,
  jobs,
  enrollments,
  schools,
  profile,
  global,
}
