import React, { useCallback, useEffect, useState } from "react"

import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { add } from "date-fns"
import { useTheme } from "react-jss"
// import { createUseStyles } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { useTimer } from "react-timer-hook"

import api from "api"
import { Attempt } from "api/resources/assessments/types"
import QuestionAttemptCard from "components/assessments/QuestionAttemptCard"
import Navbar from "components/shared/Navbar"
import toast from "utils/toast"

// const useStyles = createUseStyles(theme => ({
//   stickyBar: {
//     padding: "2rem 10vw",
//     background: theme.colors.common.white[500],
//     position: "fixed",
//     zIndex: 99,
//     border: `1px solid ${theme.colors.surface[300]}`,
//     width: "100vw",
//   },
// }))

const AttemptAssessment = () => {
  const navigate = useNavigate()
  // const classes = useStyles()
  const theme = useTheme<Theme>()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { jobApplicantStepId } = useParams()

  const [loading, setLoading] = useState(true)

  const [attempt, setAttempt] = useState<Attempt | null>(null)

  const handleSubmit = async () => {
    if (!jobApplicantStepId) return

    const res = await api.assessments.submit({
      urlParams: {
        jobApplicantStepId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Assessment Submitted")
      navigate(-1)
    }
  }

  const onAutoSubmit = () => {
    toast.success("Time's up. Your assessment was submitted automatically")
    navigate(-1)
  }

  const onTimerExpire = () => {
    handleSubmit()
  }

  const { seconds, minutes, hours, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: add(new Date(), { minutes: 10 }),
    onExpire: onTimerExpire,
  })

  const setData = (data: Attempt) => {
    setAttempt(data)
    setLoading(false)
    restart(add(new Date(data.startTime), { seconds: data.duration }))
  }

  const startAssessment = useCallback(async () => {
    if (!jobApplicantStepId) return

    const res = await api.assessments.start({
      urlParams: {
        jobApplicantStepId,
      },
    })
    if (res.isSuccessful) {
      setData(res.data)
    } else {
      toast.error("An error occurred while loading your assessment")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobApplicantStepId])

  const listQuestions = useCallback(async () => {
    if (!jobApplicantStepId) return
    const res = await api.assessments.listQuestions({
      urlParams: {
        jobApplicantStepId,
      },
    })

    if (res.isSuccessful) {
      if ("autoSubmitted" in res.data) {
        onAutoSubmit()
        return
      }

      setData(res.data)
    } else {
      startAssessment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobApplicantStepId, startAssessment])

  useEffect(() => {
    listQuestions()
  }, [listQuestions])

  if (attempt === null || loading) {
    return (
      <>
        <Navbar />
        <Container>
          <div className="flex items-center justify-center mt-20 gap-2">
            <CircularProgress />
            <Typography variant="title2">Loading Assessment</Typography>
          </div>
        </Container>
      </>
    )
  }
  return (
    <>
      <Navbar />
      <>
        <Container>
          <div className="flex flex-wrap gap-2 items-start justify-between">
            <div>
              <Typography variant="title3">{attempt.title}</Typography>
              <Typography
                className="mt-0.5"
                color="onSurface.500"
                variant="smallBody"
              >
                ({Math.floor(attempt.duration / 60)} Minutes)
              </Typography>
            </div>

            <div className="flex items-center gap-3.5">
              <Typography>
                Time Left{" "}
                <b>
                  {hours.toString().padStart(2, "0")}:
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </b>
              </Typography>
            </div>
          </div>
          <div className="flex items-center py-3.5">
            <Typography variant="strong">Questions</Typography>
            <div className="pl-2 flex-grow">
              <Divider weight="light" />
            </div>
          </div>
        </Container>

        <Container fullWidth={xsDown}>
          {attempt.tempAssessmentPerformances.map((item, index) => (
            <QuestionAttemptCard
              jobApplicantStepId={jobApplicantStepId}
              key={item.id}
              question={item}
              questionNumber={index + 1}
              onMark={response =>
                setAttempt(prevState => {
                  if (!prevState) return null
                  return {
                    ...prevState,
                    tempAssessmentPerformances:
                      prevState.tempAssessmentPerformances.map((q, i) => {
                        if (index === i) {
                          q.response = response
                        }
                        return q
                      }),
                  }
                })
              }
            />
          ))}
          <Button className="my-3 mx-2 sm:mx-0" onClick={handleSubmit}>
            Submit
          </Button>
        </Container>
      </>
    </>
  )
}

export default AttemptAssessment
