import axios from "api/axios"
import { APIResponse, ConfigWithURLParams, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AssessmentPerformance, Attempt, AutoSubmitted } from "./types"
import { urls } from "./urls"

export default {
  start: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Attempt>> => {
    try {
      const res = await axios.post(
        urls.assessment.start(urlParams.jobApplicantStepId),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listQuestions: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Attempt | AutoSubmitted>> => {
    try {
      const res = await axios.get(
        urls.assessment.listQuestions(urlParams.jobApplicantStepId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  submit: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(
        urls.assessment.submit(urlParams.jobApplicantStepId),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  markAnswer: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<
    APIResponse<AssessmentPerformance | AutoSubmitted>
  > => {
    try {
      const res = await axios.put(
        urls.assessment.markAnswer(
          urlParams.jobApplicantStepId,
          urlParams.questionId
        ),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
