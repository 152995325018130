import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { SchoolProfile } from "../schools/types"

import { Evidence, Profile, Qualification, QualificationLevel } from "./types"
import { urls } from "./urls"

export default {
  retrieve: async (): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.public())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({
    data,
    headers,
  }: Config): Promise<APIResponse<SchoolProfile>> => {
    try {
      const res = await axios.put(urls.profile.detail(), data, { headers })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  markAsComplete: async (): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.profile.markAsComplete())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listAcademics: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<
    APIResponse<{
      qualifications: Qualification[]
    }>
  > => {
    try {
      const res = await axios.get(urls.academics.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  qualification: {
    create: async ({ data }: Config): Promise<APIResponse<Qualification>> => {
      try {
        const res = await axios.post(urls.qualification.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.qualification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Qualification>> => {
      try {
        const res = await axios.put(
          urls.qualification.detail(urlParams.id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<Qualification & { evidences: Evidence[] }>
    > => {
      try {
        const res = await axios.get(urls.qualification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    evidence: {
      create: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponse<Evidence[]>> => {
        try {
          const res = await axios.post(
            urls.qualification.evidence(urlParams.id),
            data
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
        try {
          const res = await axios.delete(
            urls.qualification.evidence(urlParams.id),
            {
              data,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
  },

  listQualificationLevels: async (): Promise<
    APIResponse<QualificationLevel[]>
  > => {
    try {
      const res = await axios.get(urls.qualificationLevels.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
