import React, { useContext, useState } from "react"

import {
  Button,
  Chip,
  Tag,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { GlobalContext } from "GlobalState"
import { createUseStyles, useTheme } from "react-jss"
import { Link } from "react-router-dom"

import { Check } from "iconoir-react"

import api from "api"
import { School } from "api/resources/schools/types"
import ProfileCompletion from "components/ProfileCompletion"
import SchoolLogo from "components/shared/SchoolLogo"
import { getAuthInfo } from "utils/auth"
import { USER_TYPE } from "utils/constants"
import { getPlatformURL, isProfileComplete } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  schoolName: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  root: {
    background: theme.colors.common.white[500],
    height: "max-content",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.onSurface[200]}`,
  },
  logo: {
    filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.05))",
    maxHeight: 46,
    maxWidth: 46,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}))

type Props = {
  className?: string
  data: School
  onUpdate: (obj: School) => void
}

const SchoolCard = ({ className, data, onUpdate }: Props) => {
  const classes = useStyles()

  const theme = useTheme<Theme>()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))
  const jobsToShow = isDownSm ? 1 : 2
  const { stepsOverview } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)
  const isProfileCompleted = isProfileComplete(stepsOverview)
  const [profileCompletionDialog, toggleProfileCompletionDialog] =
    useToggle(false)

  const registerInterest = async (e?: React.MouseEvent) => {
    if (e) e.preventDefault()

    if (getAuthInfo()) {
      setLoading(true)
      const res = await api.schools.interestedUser.create({
        urlParams: {
          schoolId: data.id,
        },
      })
      if (res.isSuccessful) {
        toast.success("Great! Thank you for expressing interest in this school")
        onUpdate({ ...data, interestedInSchool: true })
      } else {
        toast.error("There was an issue while processing your request")
      }
      setLoading(false)
    } else {
      const next = encodeURIComponent(
        `${window.location.pathname}${window.location.search.replace(
          "interestedSchoolId",
          "interestedSchoolIdOld"
        )}&interestedSchoolId=${data.id}`
      )
      const redirectURL = encodeURIComponent(
        `${window.location.origin + routes.sso}?next=${next}`
      )

      window.location.href = getPlatformURL(
        "sso",
        `/?platform=${USER_TYPE}&redirect-url=${redirectURL}`
      )
    }
  }
  const completeProfileFlow = (e: React.MouseEvent) => {
    e.preventDefault()
    toggleProfileCompletionDialog(true)
  }
  return (
    <>
      {profileCompletionDialog && (
        <ProfileCompletion
          afterCompletion={registerInterest}
          open={profileCompletionDialog}
          toggle={toggleProfileCompletionDialog}
          preventRedirect
        />
      )}

      <Link
        className={classes.link}
        to={routes.schoolProfile.replace(":slug", data.slug)}
      >
        <div
          className={clsx(
            "px-3 py-3 mb-2 flex flex-col sm:flex-row gap-2",
            classes.root,
            className
          )}
        >
          <SchoolLogo
            alt="logo"
            className={clsx(classes.logo)}
            src={data.logo}
          />
          <div className="flex-grow">
            <div className={clsx("flex mb-2 justify-between")}>
              <div className="flex flex-col gap-1 items-baseline">
                <Typography className={classes.schoolName} variant="title3">
                  {data.name}
                </Typography>
                {(data.country || data.curriculumBoard.length > 0) && (
                  <div className="flex items-center flex-wrap gap-0.5">
                    {data.curriculumBoard.map((board, index) => (
                      <Tag
                        className={classes.noWrap}
                        color="primary"
                        key={index}
                        label={board.name}
                        size="sm"
                      />
                    ))}
                    {data.country && (
                      <Tag
                        color="onSurface"
                        label={`${data.state.name}, ${data.country.name}`}
                        size="sm"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {data.job.length > 0 ? (
              <div className="flex gap-1 flex-wrap items-center">
                <Typography color="onSurface.500" variant="smallBody">
                  Hiring for
                </Typography>
                {data.job.slice(0, jobsToShow).map((item, index) => (
                  <Chip
                    key={index}
                    label={`${item.position}, ${item.subject.name}`}
                    variant="outlined"
                  />
                ))}
                {data.job.length > jobsToShow && (
                  <Typography color="onSurface.500" variant="button">
                    +{data.job.length - jobsToShow} More
                  </Typography>
                )}
              </div>
            ) : (
              <div className="mt-1.25 flex gap-1 justify-between items-center flex-wrap">
                <Typography color="onSurface.600" variant="smallBody">
                  No jobs posted by school
                </Typography>
                {data.interestedInSchool ? (
                  <span>
                    <Tooltip
                      maxWidth="200px"
                      title="You'll be notified when a job opening is posted."
                    >
                      <div>
                        <Chip
                          color="success"
                          label="Interested"
                          size="sm"
                          startAdornment={<Check strokeWidth={3} />}
                        />
                      </div>
                    </Tooltip>
                  </span>
                ) : (
                  <Button
                    loading={loading}
                    size="sm"
                    variant="outlined"
                    onClick={
                      !isProfileCompleted
                        ? completeProfileFlow
                        : registerInterest
                    }
                  >
                    Register Interest
                  </Button>
                )}
              </div>
            )}

            {data.currency && data.minSalary && data.maxSalary && (
              <div className="flex gap-0.25 items-baseline mt-2">
                <Typography display="inline" variant="strong">
                  {data.currency.code}{" "}
                  {data.minSalary === data.maxSalary
                    ? `${data.minSalary.toLocaleString()}`
                    : `${data.minSalary.toLocaleString()} - ${data.maxSalary.toLocaleString()}`}
                </Typography>
                <Typography
                  color="onSurface.400"
                  display="inline"
                  variant="smallBody"
                >
                  /year
                </Typography>
              </div>
            )}
          </div>
        </div>
      </Link>
    </>
  )
}

export default SchoolCard
