import React, { useContext, useState } from "react"

import { Button, Chip, Tag, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { createUseStyles } from "react-jss"

import { Calendar, MoneySquare, PinAlt } from "iconoir-react"

import api from "api"
import {
  ApplicationStatus,
  InvitationStatus,
  Job,
} from "api/resources/jobs/types"
import AcceptApplicationDialog from "components/jobs/AcceptApplicationDialog"
import ListItem from "components/jobs/ListItem"
import RejectApplicationDialog from "components/jobs/RejectApplicationDialog"
import ProfileCompletion from "components/ProfileCompletion"
import TruncatedText from "components/shared/TruncatedText"
import SharedDialog from "components/SharedDialog"
import { getAuthInfo } from "utils/auth"
import { generateProfileCompletionURL, isProfileComplete } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
  },
  description: {
    whiteSpace: "pre-line",
  },
}))

const JobOverviewCard = ({
  className,
  data,
}: {
  className?: string
  data: Job
}) => {
  const authInfo = getAuthInfo()
  const classes = useStyles()

  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [widthdrawDialogOpen, setWidthdrawDialogOpen] = useState(false)
  const [profileCompletionDialog, toggleProfileCompletionDialog] =
    useToggle(false)
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const { stepsOverview } = useContext(GlobalContext)

  const applyForJob = async () => {
    if (!authInfo) {
      const href = generateProfileCompletionURL({
        jobId: data.id.toString(),
      })
      if (href) {
        return (window.location.href = href)
      }
    } else if (!isProfileComplete(stepsOverview)) {
      toggleProfileCompletionDialog()
    } else {
      const res = await api.jobs.jobApplicant.create({
        urlParams: { jobId: data.id },
      })

      if (res.isSuccessful) {
        toast.success("Applied for Job Successfully")
        window.location.reload()
      } else toast.error(res.errors.message || "Something went wrong")
    }
  }

  const withdrawApplication = async () => {
    if (!data.jobApplicant) return
    setWithdrawLoading(true)
    const res = await api.jobs.jobApplicant.delete({
      urlParams: {
        jobApplicantId: data.jobApplicant.id,
      },
    })
    if (res.isSuccessful) {
      setWithdrawLoading(false)
      setWidthdrawDialogOpen(false)
      toast.success("Application withdrawn")
      window.location.reload()
    } else {
      toast.error(res.errors.message)
    }
    setWithdrawLoading(false)
  }

  const action = () => {
    if (!data.jobApplicant) {
      return (
        <Button
          disabled={authInfo ? stepsOverview.loading : false}
          variant="filled"
          onClick={() => applyForJob()}
        >
          Apply Now
        </Button>
      )
    }

    const { invitationStatus, applicationStatus } = data.jobApplicant
    if (
      invitationStatus === InvitationStatus.PENDING ||
      invitationStatus === InvitationStatus.SEEN
    ) {
      return (
        <div className="flex items-center justify-end gap-1">
          <Button
            color="critical"
            variant="text"
            onClick={() => {
              setRejectDialogOpen(true)
            }}
          >
            Reject
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setAcceptDialogOpen(true)
            }}
          >
            Accept Invite
          </Button>
        </div>
      )
    }
    if (
      applicationStatus === ApplicationStatus.PENDING ||
      applicationStatus === ApplicationStatus.ACCEPTED
    ) {
      return (
        <Button
          color="critical"
          variant="text"
          onClick={() => {
            setWidthdrawDialogOpen(true)
          }}
        >
          Withdraw Application
        </Button>
      )
    }
  }

  return (
    <>
      {widthdrawDialogOpen && (
        <SharedDialog
          actionLabel="Withdraw"
          handleClose={() => setWidthdrawDialogOpen(false)}
          loading={withdrawLoading}
          open={widthdrawDialogOpen}
          title="Withdraw Application"
          isDestructive
          onConfirm={withdrawApplication}
        >
          <Typography variant="body">
            Are you sure you want to withdraw your application from{" "}
            <b>{data.school.name}’s</b> job opening for&nbsp;
            <b>
              {data.position}, {data.subject.name}
            </b>
            ?
          </Typography>
        </SharedDialog>
      )}
      {stepsOverview && (
        <ProfileCompletion
          jobId={data.id}
          open={profileCompletionDialog}
          toggle={toggleProfileCompletionDialog}
        />
      )}
      {rejectDialogOpen && data.jobApplicant && (
        <RejectApplicationDialog
          afterSubmit={() => {
            /**
             * We're directly updating props here because its convenient.
             * This kind of code usually doesn't work but here it's is only working because inside this component, we're calling `afterSubmit` first, then `handleClose`.
             * We're updating the state in handleClose which is causing a re-render.
             *
             * This code would not work if we didn't have handleClose updating the state. It wouldn't trigger a re-render.
             * Updaing parent state is not that important because it will re-fetch the data when we go back anyway.
             */
            data.jobApplicant = null
          }}
          handleClose={() => {
            setRejectDialogOpen(false)
          }}
          jobApplicantId={data.jobApplicant.id}
          jobPosition={data.position}
          mode="invitation"
          open={rejectDialogOpen}
          schoolName={data.school.name}
        />
      )}

      {acceptDialogOpen && data.jobApplicant && (
        <AcceptApplicationDialog
          afterSubmit={() => {
            setAcceptDialogOpen(false)
            toast.success("Invite accepted", {
              duration: 7000,
              body: "Please check Active Applications Tab",
            })
          }}
          handleClose={() => {
            setAcceptDialogOpen(false)
          }}
          jobApplicantId={data.jobApplicant.id}
          jobPosition={data.position}
          open={acceptDialogOpen}
          schoolName={data.school.name}
          subjectName={data.subject.name}
        />
      )}

      <div className={clsx(classes.root, className)}>
        <div className="flex items-center justify-between flex-wrap gap-2">
          <div>
            <span className="flex gap-1 items-center">
              <Typography variant="strong">{data.position}</Typography>
              <div className="flex gap-1 items-center">
                {data.school.curriculumBoard.map(item => (
                  <Chip
                    key={item.uuid}
                    label={item.name}
                    size="sm"
                    variant="outlined"
                  />
                ))}
              </div>
            </span>
            <Typography color="onSurface.500">{data.school.name}</Typography>
          </div>
          {action()}
        </div>
        <Tag className="my-3" label={data.subject.name} />
        <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-center md:justify-between mt-3">
          <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-6">
            <span>
              <ListItem icon={<PinAlt />}>Location</ListItem>
              <Typography className="ml-0.5" variant="strong">
                {data.school.state?.name && data.school.country?.name
                  ? `${(data.school.state.name, data.school.country.name)}`
                  : "-"}
              </Typography>
            </span>
            <span>
              <ListItem icon={<Calendar />}>Posted on</ListItem>
              <Typography className="ml-0.5" variant="strong">
                {data.datePublished
                  ? format(new Date(data.datePublished), "d MMM yyyy")
                  : "-"}
              </Typography>
            </span>
            <span>
              <ListItem icon={<MoneySquare />}>Salary Offered</ListItem>
              <div className="flex items-center">
                <Typography className="ml-0.5" variant="strong">
                  {data.currency.code} {data.salaryOffered.toLocaleString()}
                  {data.maximumSalaryOffered ? (
                    <> - {data.maximumSalaryOffered.toLocaleString()}</>
                  ) : null}
                </Typography>
                <Typography color="onSurface.500" variant="smallBody">
                  {" "}
                  /year
                </Typography>
              </div>
            </span>
          </div>

          {/* <span className="flex items-center gap-0.5">
            <Check color={theme.colors.success[500]} />
         <Typography color="success.500">Applied</Typography>
          </span> */}
        </div>
        {data.description && (
          <TruncatedText
            className={`${classes.description} mt-2.5`}
            color="onSurface.600"
            maxLength={400}
            variant="body"
          >
            {data.description}
          </TruncatedText>
        )}
      </div>
    </>
  )
}

export default JobOverviewCard
