import { useContext, useEffect, useState } from "react"

import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tag,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { format, isToday, isTomorrow } from "date-fns"
import { createUseStyles } from "react-jss"
import { createSearchParams, Link, useNavigate } from "react-router-dom"

import { MoreVert, QuestionMarkCircle } from "iconoir-react"

import api from "api"
import { ActiveApplication, BaseStep, StepType } from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import NoDataCard from "components/shared/NoDataCard"
import SchoolLogo from "components/shared/SchoolLogo"
import SharedDialog from "components/SharedDialog"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { TabContext } from "views/Home/TabContext"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },

  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.25fr 2fr 1fr 50px",
    gap: theme.spacing(2),
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },
  offerRecieved: {
    backgroundColor: theme.colors.success[50],
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))

const getStepName = ({
  upcomingStepCount,
  upcomingSteps,
  jobOffer,
}: ActiveApplication) => {
  if (jobOffer)
    return (
      <Typography color="success.700" variant="smallBody">
        Offer letter received
      </Typography>
    )

  if (!upcomingSteps || upcomingSteps.length === 0)
    return (
      <Typography color="onSurface.500" variant="smallBody">
        Awaiting next action from recruiter
      </Typography>
    )

  const getDueDate = (date: string, isAssessment = false) => {
    const dateObj = new Date(date)

    const getPrefix = () => {
      if (isToday(dateObj)) return <b>today</b>
      if (isTomorrow(dateObj)) return <b>tomorrow</b>
      return `${isAssessment ? "by" : "on"} ${format(dateObj, "d MMM yyyy")}`
    }

    return (
      <>
        {getPrefix()} at {format(new Date(date), "hh:mm a")}
      </>
    )
  }

  const getContent = (step: BaseStep, showTag = true) => {
    switch (step.stepType) {
      case StepType.INTERVIEW:
        return (
          <>
            {showTag && <Tag label="Interview" size="sm" />}
            {step.dueDate && (
              <Typography variant="smallBody">
                {!showTag && "Interview -"} Starts {getDueDate(step.dueDate)}
              </Typography>
            )}
          </>
        )

      case StepType.LIVE_DEMO:
        return (
          <>
            {showTag && (
              <Tag className="flex-shrink-0" label="Live Demo" size="sm" />
            )}
            {step.dueDate && (
              <Typography variant="smallBody">
                {!showTag && "Live Demo -"} Starts {getDueDate(step.dueDate)}
              </Typography>
            )}
          </>
        )

      case StepType.ASSESSMENT:
        return (
          <>
            {showTag && <Tag label="Assessment" size="sm" />}
            {step.dueDate && (
              <Typography variant="smallBody">
                {!showTag && "Assessment -"} Due&nbsp;
                {getDueDate(step.dueDate, true)}
              </Typography>
            )}
          </>
        )
      default:
        return ""
    }
  }

  return (
    <>
      {upcomingSteps.map((step, index) => (
        <div className="flex gap-1 mb-0.5" key={index}>
          {getContent(step, index === 0)}
        </div>
      ))}

      <Typography className="mt-0.5" color="onSurface.500" variant="smallBody">
        {upcomingStepCount > 0 && `+${upcomingStepCount} more`}
      </Typography>
    </>
  )
}

const ActiveApplicationsTab = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [page, setPage] = useState(1)

  const { setOverview } = useContext(TabContext)

  const [applications, setApplications] = useState<
    PaginatedResponse<ActiveApplication[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const [applicationToDelete, setApplicationToDelete] =
    useState<ActiveApplication | null>(null)

  useEffect(() => {
    setLoading(true)
    const listApplicants = async () => {
      const res = await api.jobs.jobApplicant.listActive({
        params: {
          page,
          ordering: "due_date",
        },
      })
      if (res.isSuccessful) {
        setApplications(res.data)
        setLoading(false)
      }
    }

    listApplicants()
  }, [page])

  const actions = (item: ActiveApplication) => {
    if (item.jobOffer)
      return (
        <Button
          component={Link}
          size="sm"
          to={routes.viewOffer.replace(":jobOfferId", String(item.jobOffer.id))}
          variant="outlined"
        >
          View Offer Letter
        </Button>
      )

    if (!item.upcomingSteps || item.upcomingSteps.length === 0) return null

    return (
      <Button
        className="-ml-0.75"
        component={Link}
        size="sm"
        to={`${routes.jobTimeline
          .replace(":jobId", String(item.job.id))
          .replace(":jobApplicant", String(item.id))}`}
        variant="text"
      >
        View Timeline
      </Button>
    )
  }

  const deleteApplication = async () => {
    if (!applicationToDelete) return

    setDeleteLoading(true)

    const res = await api.jobs.jobApplicant.delete({
      urlParams: {
        jobApplicantId: applicationToDelete.id,
      },
    })

    if (res.isSuccessful) {
      setApplicationToDelete(null)
      setApplications(prevState => ({
        ...prevState,
        data: prevState.data.filter(({ id }) => id !== applicationToDelete.id),
      }))
      setOverview(prevState => ({
        ...prevState,
        totalActiveApplications: prevState.totalActiveApplications - 1,
      }))
      toast.success("Application Withdrawn")
    } else {
      toast.error(res.errors.message)
    }
    setDeleteLoading(false)
  }

  if (loading) {
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    )
  }

  if (applications.data.length === 0) {
    return (
      <NoDataCard
        className="mt-3"
        message="You don't have any active applications yet."
      />
    )
  }

  return (
    <>
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-2")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>
            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">Current Step</Typography>
              <Tooltip
                offsetY={10}
                title="Your current stage in the hiring process"
              >
                <QuestionMarkCircle
                  color="#64748B"
                  style={{ height: "20px", width: "20px" }}
                />
              </Tooltip>
            </div>
          </div>
          {applications.data.map((item, index) => (
            <div
              className={clsx(classes.tableGrid, classes.tableItem, "py-2", {
                [classes.offerRecieved]: Boolean(item.jobOffer),
              })}
              key={index}
            >
              <div className="pl-3 mt-0.5">
                <SchoolLogo className={classes.image} src={item.school.logo} />
              </div>
              <div>
                <div className="flex items-center gap-0.5">
                  <Button
                    className="-ml-0.75"
                    component={Link}
                    to={`${routes.jobTimeline
                      .replace(":jobId", String(item.job.id))
                      .replace(
                        ":jobApplicant",
                        String(item.id)
                      )}?${createSearchParams({
                      jobOfferId: item.jobOffer?.id
                        ? String(item.jobOffer?.id)
                        : "",
                      tab: "Profile",
                    })}`}
                    variant="link"
                  >
                    <Typography
                      color="interactive.600"
                      display="inline"
                      variant="smallBody"
                    >
                      {item.school.name}
                    </Typography>
                  </Button>
                </div>
                {item.school.state?.name && item.school.country?.name && (
                  <Typography
                    className={clsx(classes.textSmall, "mb-0.5")}
                    color="onSurface.500"
                  >
                    {item.school.state.name}, {item.school.country.name}
                  </Typography>
                )}

                <Typography variant="smallBody">{item.job.position}</Typography>

                <Typography variant="smallBody">
                  {item.job.subject.name}
                </Typography>
              </div>
              <div className="flex flex-col justify-center">
                {getStepName(item)}
              </div>
              <div className="flex flex-col items-end justify-center">
                {actions(item)}
              </div>
              <div className="flex items-center justify-end mr-1.25">
                <Menu
                  menuButton={
                    <IconButton>
                      <MoreVert />
                    </IconButton>
                  }
                  portal
                >
                  <MenuItem
                    onClick={() => {
                      navigate(
                        `${routes.jobTimeline
                          .replace(":jobId", String(item.job.id))
                          .replace(
                            ":jobApplicant",
                            String(item.id)
                          )}?${createSearchParams({
                          jobOfferId: item.jobOffer?.id
                            ? String(item.jobOffer?.id)
                            : "",
                        })}`
                      )
                    }}
                  >
                    View Timeline
                  </MenuItem>
                  <MenuItem
                    color="critical"
                    onClick={() => {
                      setApplicationToDelete(item)
                    }}
                  >
                    Withdraw Application
                  </MenuItem>
                </Menu>
              </div>
            </div>
          ))}
        </div>
      </div>

      {applications.total > 10 && (
        <Pagination page={page} total={applications.total} onChange={setPage} />
      )}

      <SharedDialog
        actionLabel="Withdraw"
        handleClose={() => setApplicationToDelete(null)}
        loading={deleteLoading}
        open={Boolean(applicationToDelete)}
        title="Withdraw Application"
        isDestructive
        onConfirm={() => deleteApplication()}
      >
        <Typography variant="body">
          This will remove you from this school's hiring process. Are you sure?
        </Typography>
      </SharedDialog>
    </>
  )
}

export default ActiveApplicationsTab
