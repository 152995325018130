import React from "react"

import * as Sentry from "@sentry/react"
import ReactDOM from "react-dom"

import ErrorScreen from "components/ErrorScreen"
import { getAuthInfo } from "utils/auth"

import { version } from "../package.json"

import App from "./App"

import "./styles/tailwind.generated.css"

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: version,
  })

  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

if (!process.env.REACT_APP_SSO_URL) {
  throw new Error("Please add REACT_APP_SSO_URL in your environment variables.")
}

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById("root") || document.createElement("div")
)
