import React, { PropsWithChildren } from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  tab: {
    ...theme.typography.body,
    color: theme.colors.onSurface[500],
    marginRight: theme.spacing(4),
    padding: theme.spacing(0, 0.25, 1, 0.25),
    whiteSpace: "nowrap",

    "&$active": {
      ...theme.typography.strong,
      color: theme.colors.primary[500],
      borderBottom: `2px solid ${theme.colors.primary[500]}`,
    },
  },
  active: {},
}))

type Props = {
  isActive: boolean
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const TabItem = ({
  children,
  isActive,
  ...props
}: PropsWithChildren<Props>) => {
  const classes = useStyles()
  return (
    <button
      className={clsx(classes.tab, { [classes.active]: isActive })}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default TabItem
