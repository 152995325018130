import {
  Country,
  Currency,
  Curriculum,
  State,
  Subject,
} from "api/resources/global/types"

export enum WorkingDays {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum TeachingMode {
  ONLINE = 1,
  OFFLINE = 2,
  HYBRID = 3,
}

export type Gallery = { id: number; photo: string }

export type Amenity = { name: string; id: string }

export type Perk = { name: string; id: string }

export type ManagementMessage = {
  id: number
  name: string
  image: string
  message: string
  position: string
  school: number
}

export type SchoolProfile = {
  id: number
  branch: string | null
  name: string
  slug: string
  website: string
  email: string
  phoneNumber: {
    countryCode: number
    number: number
  } | null
  address: string
  description: string
  logo: string | null
  city: string | null
  pincode: number | null
  coverImage: string | null
  dateEstablished: string | null
  workingDays: WorkingDays[]
  dayStartTime: string
  dayEndTime: string
  teachingMode: TeachingMode | null
  isVerified: boolean
  managementMessage?: ManagementMessage | null
  curriculumBoard?: Curriculum[]
  galleryCount: number
  gallery?: Gallery[]
  perks?: Perk[]
  amenities?: Amenity[]
  country: Country | null
  state: State | null
}

export type GalleryItem = {
  id: number
  photo: string
  title: string
  school: number
}

export type School = {
  id: number
  name: string
  interestedInSchool: boolean
  slug: string
  logo: string | null
  curriculumBoard: Curriculum[]
  country: Country | null
  state: State
  job: {
    subject: Subject
    position: string
  }[]
  maxSalary: number | null
  minSalary: number | null
  currency: Currency | null
}
