import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  Country,
  Curriculum,
  QualificationField,
  State,
  Subject,
} from "./types"
import { urls } from "./urls"

export default {
  subject: {
    list: async ({ params }: Config): Promise<APIResponse<Subject[]>> => {
      try {
        const res = await axios.get(urls.subject.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  curricula: {
    list: async ({ params }: Config): Promise<APIResponse<Curriculum[]>> => {
      try {
        const res = await axios.get(urls.curricula.list(), { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  countries: {
    list: async ({ params }: Config): Promise<APIResponse<Country[]>> => {
      try {
        const res = await axios.get(urls.countries.list(), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  states: {
    list: async ({
      urlParams,
      params,
    }: ConfigWithURLParams): Promise<APIResponse<State[]>> => {
      try {
        const res = await axios.get(urls.states.list(urlParams.country), {
          params,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  qualificationFields: {
    // This is bulk-create
    create: async ({
      data,
    }: Config): Promise<APIResponse<QualificationField[]>> => {
      try {
        const res = await axios.post(urls.qualificationFields.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    list: async ({
      params: { page = -1 },
    }: Config): Promise<APIResponse<QualificationField[]>> => {
      try {
        const res = await axios.get(urls.qualificationFields.list(), {
          params: { page },
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
