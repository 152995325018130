import React, { useContext, useEffect, useState } from "react"

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import { ReactComponent as Logo } from "assets/logos/suraasa-logo-new.svg"
import clsx from "clsx"
import { GlobalContext } from "GlobalState"
import { FormProvider, useForm } from "react-hook-form"
import { createUseStyles, useTheme } from "react-jss"

import { Cancel } from "iconoir-react"

import { STEPS } from "components/ProfileCompletion/constants"
import AcademicDetails from "components/ProfileCompletion/Form/AcademicDetails"
import Congratulations from "components/ProfileCompletion/Form/Congratulations"
import JobOverview from "components/ProfileCompletion/Form/JobOverview"
import MarketingData from "components/ProfileCompletion/Form/MarketingData"
import PersonalDetails from "components/ProfileCompletion/Form/PersonalDetails"
import Timeline from "components/ProfileCompletion/Timeline"
import { isProfileComplete } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import { ToggleValue } from "utils/hooks/useToggle"

import {
  Form,
  ProfileCompletionContext,
  ProfileCompletionContextType,
} from "./utils"

const useStyles = createUseStyles(theme => ({
  root: {
    width: "96%",
    height: "100%",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      padding: "16px",
    },
    "& .DialogContent-root": {
      height: "100%",
    },
  },
  content: {
    flex: 1,
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },

  contentBorder: {
    paddingLeft: theme.spacing(3),
    marginLeft: theme.spacing(3),
    borderLeft: `1px solid ${theme.colors.onSurface[300]}`,
  },
  cancelIcon: {
    position: "absolute",
    top: 16,
  },
}))

type Props = {
  open: boolean
  toggle?: ToggleValue
  jobId?: number
  afterCompletion?: () => void
  preventRedirect?: boolean
}

const ProfileCompletion = ({
  open,
  toggle,
  jobId,
  afterCompletion,
  preventRedirect,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))

  const { stepsOverview, setStepsOverview } = useContext(GlobalContext)

  const methods = useForm<Form>({
    mode: "all",
    defaultValues: {
      lookingForJobs: null,
      areYouCurrentlyTeaching: null,
    },
  })

  const [step, setStep] = useState<ProfileCompletionContextType["step"]>(
    STEPS.PersonalDetails
  )

  const { countries, subjects } = useResources(["countries", "subjects"])

  const getStep = () => {
    switch (step) {
      case STEPS.JobOverview:
        return (
          stepsOverview &&
          jobId && <JobOverview jobId={jobId} stepsOverview={stepsOverview} />
        )
      case STEPS.PersonalDetails:
        return <PersonalDetails />
      case STEPS.MarketingData:
        return <MarketingData />
      case STEPS.AcademicDetails:
        return <AcademicDetails afterCompletion={afterCompletion} />
      case STEPS.Congratulations:
        return (
          <Congratulations
            jobId={jobId}
            preventRedirect={preventRedirect}
            setStepsOverview={setStepsOverview}
          />
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (!stepsOverview) return

    const {
      marketingFormDataExists,
      profileDataExists,
      qualificationDataExists,
    } = stepsOverview

    if (jobId && stepsOverview._extra?.autoRedirect) {
      setStep(STEPS.JobOverview)
    } else if (!profileDataExists) {
      setStep(STEPS.PersonalDetails)
    } else if (!marketingFormDataExists) {
      setStep(STEPS.MarketingData)
    } else if (!qualificationDataExists) {
      setStep(STEPS.AcademicDetails)
    } else if (
      isProfileComplete(stepsOverview) &&
      stepsOverview.marketingFormDataExists
    )
      setStep(STEPS.Congratulations)
  }, [stepsOverview, jobId])

  return (
    <Dialog
      className={classes.root}
      fullScreen={isSm}
      open={open}
      onRequestClose={
        toggle
          ? () => {
              methods.reset({})
              toggle(false)
            }
          : undefined
      }
    >
      {isSm && (
        <DialogTitle>
          <Logo height={20} />
        </DialogTitle>
      )}
      <DialogContent className="flex flex-col md:flex-row">
        {step !== STEPS.JobOverview && <Timeline step={step} />}
        <div
          className={clsx(classes.content, "pb-6 sm:mx-8 md:pb-2 md:mx-0", {
            [classes.contentBorder]: step !== STEPS.JobOverview && !isSm,
          })}
        >
          {!isSm && toggle && (
            <div className="flex justify-end">
              <IconButton
                className={classes.cancelIcon}
                color="black"
                onClick={() => {
                  methods.reset({})
                  toggle(false)
                }}
              >
                <Cancel />
              </IconButton>
            </div>
          )}

          <ProfileCompletionContext.Provider
            value={{ step, setStep, countries, subjects, open, toggle }}
          >
            <FormProvider {...methods}>{getStep()}</FormProvider>
          </ProfileCompletionContext.Provider>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ProfileCompletion
