import React, { useContext } from "react"

import { Typography } from "@suraasa/placebo-ui"

import SchoolProfileContext from "views/schoolProfile/context"

import CheckTile from "../CheckTile"

function Amenities() {
  const { amenities } = useContext(SchoolProfileContext)

  return (
    <>
      <div className="flex items-center justify-between">
        <Typography className="mb-3" color="primary.500" variant="preTitle">
          Amenities
        </Typography>
      </div>

      {amenities.data.length ? (
        <div className="flex flex-col flex-wrap mb-0.5 sm:flex-row sm:justify-start">
          {amenities.data.map(item => (
            <CheckTile key={item.id} label={item.name} />
          ))}
        </div>
      ) : (
        <Typography
          className="mb-0.5"
          color="onSurface.400"
          variant="smallBody"
        >
          Looks like there's nothing here
        </Typography>
      )}
    </>
  )
}

export default Amenities
