import { ModeOfLearning } from "utils/constants"

import { QualificationField } from "../global/types"

export type QualificationLevel = {
  uuid: string
  name: string
}
export type Qualification = {
  id: number
  organisationName: string
  name: string
  qualificationLevelId: string
  qualificationFieldId: string
  qualificationField: QualificationField
  qualificationLevel: QualificationLevel
  startDate: string // Date
  endDate: string // Date
  grade: string
  modeOfLearning: ModeOfLearning
  isVerified: boolean
  evidences: Evidence[]
}

export enum EvidenceType {
  URL = 1,
  File,
}

type BaseEvidence = {
  id: string
}

export type Evidence =
  | (BaseEvidence & {
      evidenceType: EvidenceType.URL
      url: string
    })
  | (BaseEvidence & {
      evidenceType: EvidenceType.File
      file: string
      fileName: string
    })

export enum Gender {
  MALE = 1,
  FEMALE,
  PREFER_NOT_TO_SAY,
}
export type Profile = {
  id: string
  careerAspiration: string | null
  countriesWorkedIn: { name: string }[]
  // currentWorkingExperiences: WorkExperienceType[]
  countryId: string | null
  country: { name: string; code: string; uuid: string } | null
  dateOfBirth: string | null
  gender: Gender | null
  qualificationField: QualificationField
  isVerified: boolean
  lookingForJobs: boolean
  nationality: { name: string; code: string; uuid: string } | null
  state: { name: string; uuid: string } | null
  stateId: string | null
  phoneNumber: { code: string; number: number } | null
  picture: string | null
  user: {
    email: string
    firstName: string
    lastName: string
    middleName: string
    username: string
    uuid: string
  }
}
