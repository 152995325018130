// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react"

import { Helmet, HelmetProps } from "react-helmet"

interface Props extends HelmetProps {
  data: {
    title: string
    description?: string
    url?: string
    coverImage?: string
    type?: string
  }
  dynamicContent?: string | null
}

// TODO: Format: <dynamic content> | <page name> | Suraasa Jobs
const ReactHelmet = ({ data, dynamicContent, ...props }: Props) => {
  const type = data.type || "website"

  const title = dynamicContent
    ? `${dynamicContent} | ${data.title}`
    : data.title

  return (
    <Helmet
      defaultTitle="Suraasa Jobs"
      title={title}
      titleTemplate="%s | Suraasa Jobs"
      {...props}
    >
      {/* <!-- Primary Meta Tags --> */}
      {title && <meta content={title} name="title" />}
      {data.description && (
        <meta content={data.description} name="description" />
      )}

      {/* <!-- Open Graph / Facebook --> */}
      <meta content={type} property="og:type" />
      {data.url && <meta content={data.url} property="og:url" />}
      {title && <meta content={title} property="og:title" />}
      {data.description && (
        <meta content={data.description} property="og:description" />
      )}
      {data.coverImage && (
        <meta content={data.coverImage} property="og:image" />
      )}

      {/* <!-- Twitter --> */}
      {data.coverImage && (
        <meta content={data.coverImage} property="twitter:card" />
      )}
      {data.url && <meta content={data.url} property="twitter:url" />}
      {title && <meta content={title} property="twitter:title" />}
      {data.description && (
        <meta content={data.description} property="twitter:description" />
      )}
      {data.description && (
        <meta content={data.description} property="twitter:image" />
      )}
    </Helmet>
  )
}

export default ReactHelmet
