import { Country, Currency, Curriculum, State, Subject } from "../global/types"

export type Job = {
  school: Pick<
    School,
    | "country"
    | "curriculumBoard"
    | "logo"
    | "name"
    | "state"
    | "email"
    | "slug"
    | "branch"
    | "city"
  >
  jobApplicant: JobApplicant | null
  id: number
  slug: string
  position: string
  subject: Subject
  currency: Currency
  salaryOffered: number
  maximumSalaryOffered: number | null
  datePublished: string | null
  numberOfApplicants: string | null
  description: string | null
}

export type ActiveJobOverview = {
  newInvites: number
  newOffers: number
  totalActiveApplications: number
  totalApplied: number
  totalInvites: number
  totalJobOffers: number
}

export type Overview = ActiveJobOverview | AcceptedJobOverview

export type JobOverview = Pick<Job, "id" | "slug" | "position" | "subject">

export type JobApplicant = {
  id: number
  applicationStatus: ApplicationStatus | null
  invitationStatus: InvitationStatus | null
  invitationSentOn: string // ISODate
  applicationSentOn: string // ISODate
  status: JobApplicantStepStatus
  attemptNumber: number
  rejectionReason: string | null
  isViewed: boolean
  job: number
  user: string // userID
}

export enum InvitationStatus {
  SEEN = 1,
  PENDING,
  ACCEPTED,
  REJECTED,
  REVOKED,
}

export enum ApplicationStatus {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export enum JobOfferStatus {
  SENT = 1,
  ACCEPTED,
  REJECTED,
}

export type JobOverviewType = {
  uuid: string
  eligibleForJobs: boolean
  jobsLockedTill: string | null
}

export type AcceptedJobOverview = {
  jobsLockedTill?: string
  jobOffer?: JobOfferDetailsType
}

export type JobOfferDetailsType = {
  description: string
  finalSalary: number
  currency: Currency
  dateCreated: string
  joiningDate: string
  offerLetter: string
  offerLetterName: string
  status: JobOfferStatus
  job: Job
  user: string
}

export type SchoolOverview = Pick<
  School,
  "id" | "logo" | "name" | "country" | "state" | "slug"
>

export type JobOfferType = {
  id: number
  status: JobOfferStatus
  school: SchoolOverview
  job: JobOverview
  position: string
  dateCreated: string
  user: number
  isViewed: boolean
  subject: Subject
  currency: Currency
  salaryOffered: number
  maximumSalaryOffered: number | null
}

export type School = {
  id: number
  branch: string | null
  city: string | null
  name: string
  country: Country | null
  state: State | null
  slug: string
  website: string
  workingDays: number
  workHoursPerDay: number
  phoneNumber: string
  email: string
  teachingMode: number
  admin: string
  logo: string | null
  coverImage: string
  description: string
  dateEstablished: string
  isVerified: boolean
  curriculumBoard: Curriculum[]
}

/**
 * Timeline
 */
export type BaseStep = {
  dateCreated: string
  dueDate: string
  id: number
  step: InterviewDetailsType | LiveDemoDetailsType | AssessmentDetailsType
  stepId: number
  // stepNumber: number
  stepType: StepType
  status: JobApplicantStepStatus
}

export interface AssessmentDetailsStep extends BaseStep {
  stepType: StepType.ASSESSMENT
  step: AssessmentDetailsType
}
export interface InterviewDetailsStep extends BaseStep {
  stepType: StepType.INTERVIEW
  step: InterviewDetailsType
}
export interface LiveDemoDetailsStep extends BaseStep {
  stepType: StepType.LIVE_DEMO
  step: LiveDemoDetailsType
}

export type Step =
  | AssessmentDetailsStep
  | LiveDemoDetailsStep
  | InterviewDetailsStep

export enum StepType {
  LIVE_DEMO = "livedemo",
  INTERVIEW = "interview",
  ASSESSMENT = "assessment",
}

export type AssessmentDetailsType = {
  id: number
  title: string
  duration: number
  dueDate: string | null
  dateCreated: string
  scheduledTime: string
  totalNumberOfQuestions: number
}

export type InterviewDetailsType = {
  scheduledTime: string
  id: number
  duration: number
  name: string
  isTelephonic: boolean
  videoMeeting: any | null
  url: string | null
}

export type LiveDemoDetailsType = {
  id: number
  name: string
  scheduledTime: string
  instructions: string
  isVirtual: boolean
  duration: number
  topic: string | null
  address: string | null
  meetingUrl: string | null
}

export enum JobApplicantStepStatus {
  NOT_STARTED = 1,
  IN_PROGRESS,
  COMPLETED,
  SKIPPED,
}

export type CurrentStep = Step & {
  score: number | null
  startTime: string | null // ISODate
  endTime: string | null // ISODate
}

export enum JobApplicantStatus {
  PENDING = 1,
  IN_PROGRESS = 2,
  REJECTED = 3,
  JOB_OFFER_SENT = 4,
  JOB_OFFER_ACCEPTED = 5,
  JOB_OFFER_REJECTED = 6,
  WITHDRAWN = 7,
}

export type ActiveApplication = {
  id: number
  job: {
    id: number
    position: string
    subject: {
      uuid: string
      name: string
      addedById: string | null
      isApproved: boolean
    }
  }
  jobOffer?: JobOfferType
  status: JobApplicantStatus
  school: {
    slug: string
    country: {
      uuid: string
      name: string
      isoCode: string
      isoCode3: string
    } | null
    state: {
      uuid: string
      name: string
      isoCode: string
      countryId: string
    } | null
    name: string
    logo: string
  }
  upcomingSteps: BaseStep[]
  upcomingStepCount: number
}

export type Application = {
  id: number
  invitationExpiresAt: string
  school: {
    slug: string
    country: {
      uuid: string
      name: string
      isoCode: string
      isoCode3: string | null
    } | null
    state: {
      uuid: string
      name: string
      isoCode: string
      countryId: string
    } | null
    name: string
    logo: string | null // URL
  }
  job: {
    id: number
    subject: {
      uuid: string
      name: string
      addedById: string | null
      isApproved: boolean
    }
    position: string
  }
  isViewed: boolean
  applicants: number
  dateApplied: string
}

export type StepsOverview = {
  profileDataExists: boolean
  marketingSignupComplete: boolean
  marketingFormDataExists: boolean
  qualificationDataExists: boolean
}
