import React from "react"

import {
  Button,
  ButtonComponentProps,
  Chip,
  Divider,
  Typography,
} from "@suraasa/placebo-ui"

type Props = {
  title: string
  answer: string | string[]
  actionProps: Partial<ButtonComponentProps<"button">>
}

const AnsweredQuestion = ({ title, answer, actionProps }: Props) => {
  const isArray = Array.isArray(answer)

  return (
    <>
      <div className="flex justify-between flex-wrap gap-1 items-center">
        <div className="flex-0 md:flex-1">
          <Typography display={isArray ? "block" : "inline"} variant="body">
            {title}
          </Typography>

          {!isArray && (
            <Typography
              className="ml-2"
              display={isArray ? "block" : "inline"}
              variant="strong"
            >
              {answer}
            </Typography>
          )}

          <div className="flex gap-1 mt-1 flex-wrap">
            {isArray &&
              answer.map(item => (
                <Chip key={item} label={item} variant="outlined" />
              ))}
          </div>
        </div>

        <Button label="Change" variant="text" {...actionProps} />
      </div>
      <Divider className="mt-2 mb-3" color="onSurface.200" weight="light" />
    </>
  )
}

export default AnsweredQuestion
