import { theme } from "@suraasa/placebo-ui"
import { UseFormSetError } from "react-hook-form"
import { NavigateFunction } from "react-router-dom"

import { StepsOverview } from "api/resources/jobs/types"
import { SchoolProfile } from "api/resources/schools/types"
import { ErrorResponse } from "api/types"
import { routes } from "utils/routes"

import { getAuthInfo } from "./auth"
import { NotificationAction, USER_TYPE } from "./constants"

export const handleErrors = <T>(
  setter: UseFormSetError<T>,
  { fieldErrors }: ErrorResponse["errors"]
) => {
  if (fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      setter(k as any, { message: v })
    }
  }
}

export const buildParams = (rawParams: {
  [key: string]: string | string[]
}) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(rawParams)) {
    if (!value) continue

    if (Array.isArray(value)) {
      params.append(key, value.join())
    } else params.append(key, value)
  }

  return params
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export type ValueOf<T> = T[keyof T]

export const getPlatformURL = (
  platform: "sso" | "learn" | "suraasa",
  url: string
) => {
  switch (platform) {
    case "sso":
      return `${process.env.REACT_APP_SSO_URL}${url}`
    case "learn":
      return `${process.env.REACT_APP_LEARN_PLATFORM_URL}${url}`
    case "suraasa":
      return `${process.env.REACT_APP_SURAASA_PLATFORM_URL}${url}`
    default:
      return url
  }
}

export const generateHuddleURL = (config: {
  meetingUrl: string
  displayName?: string
}) => {
  const authInfo = getAuthInfo()
  if (!authInfo) return ""

  const meetingURL = new URL(config.meetingUrl)
  meetingURL.searchParams.append(
    "displayName",
    `${authInfo.user.firstName} ${authInfo.user.lastName}`
  )

  const searchParams = new URLSearchParams()

  searchParams.append("meeting-link", encodeURIComponent(meetingURL.href))

  return `${routes.attemptInterview}?${searchParams.toString()}`
}

export const isProfileComplete = (
  overview: StepsOverview & { loading: boolean }
) => {
  if (overview.loading) return false
  return (
    overview.marketingFormDataExists &&
    overview.profileDataExists &&
    overview.qualificationDataExists
  )
}

export const generateProfileCompletionURL = (config: { jobId: string }) => {
  try {
    const nextUrl = new URL(window.location.href)
    nextUrl.searchParams.append("applyingOnJobId", config.jobId)

    if (process.env.REACT_APP_SSO_URL) {
      // const url = new URL(process.env.REACT_APP_SSO_URL)
      // url.searchParams.append("platform", USER_TYPE)
      // url.searchParams.append("next", nextUrl.href)
      // url.searchParams.append("redirect-url", window.location.origin)
      return `${
        process.env.REACT_APP_SSO_URL
      }/?platform=${USER_TYPE}&redirect-url=${encodeURIComponent(
        `${window.location.origin}?next=${encodeURIComponent(
          nextUrl.pathname + nextUrl.search
        )}`
      )}`
    }
  } catch (error) {
    console.error(error)
    return process.env.REACT_APP_SSO_URL
  }
}

export const notificationHelper = (
  navigate: NavigateFunction,
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: NotificationAction | {} | null
) => {
  if (!action || !("name" in action)) return
  switch (action.name) {
    case "JOB_OFFER_RECEIVED":
      navigate(
        routes.viewOffer.replace(":jobOfferId", String(action.data.jobOfferId))
      )
      break
    case "INTERVIEW_SCHEDULED":
    case "LIVE_DEMO_SCHEDULED":
    case "SCHOOL_ASSESSMENT_SCHEDULED":
      navigate(
        `${routes.jobTimeline
          .replace(":jobId", String(action.data.jobId))
          .replace(
            ":jobApplicant",
            action.data.jobApplicant.toString()
          )}?jobApplicantStepId=${action.data.jobApplicantStepId}&tab=Timeline`
      )
      break
    case "INVITE_RECEIVED":
      navigate(
        `${routes.home}?tab=Invitations&inviteId=${action.data.jobApplicantId}`
      )
      break
    case "JOB_PUBLISHED":
      navigate(
        `${routes.schoolProfile.replace(
          ":slug",
          action.data.schoolSlug
        )}?jobSlug=${action.data.jobSlug}`
      )
      break
    default:
      break
  }
}

/**
 * @returns An array of timeouts which the consumer must use to clear the timeouts in case they are not needed.
 * This is useful for preventing unusal side-effects
 */
export const highlightElement = ({
  element,
  onEnd,
}: {
  element: HTMLElement | null
  onEnd?: () => void
}) => {
  const timeouts = []

  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "center" })
    timeouts.push(
      setTimeout(() => {
        element.style.transition = "background-color 300ms"
        // eslint-disable-next-line prefer-destructuring
        element.style.backgroundColor = theme.colors.primary[50]
      }, 400)
    )

    timeouts.push(
      setTimeout(() => {
        element.style.backgroundColor = ""
        if (onEnd) onEnd()
      }, 2500)
    )
  }

  return timeouts
}

export const getShareJobContent = (
  platform: "linkedin" | "whatsapp" | "facebook" | "telegram",
  {
    profile,
    job,
  }: {
    profile: Pick<
      SchoolProfile,
      "name" | "slug" | "branch" | "city" | "state" | "country"
    >
    job: { position: string; subject: string; slug: string }
  }
) => {
  const skipEmojis = platform === "facebook"
  const shareURL = `${window.location.origin}/school/${profile.slug}?jobSlug=${job.slug}`

  const renderEmoji = (emoji: string) => {
    if (skipEmojis) return ""
    return `${emoji} `
  }

  const heading = `${renderEmoji("👋")}Hi, we are hiring a ${job.position} at ${
    profile.name
  }${profile.branch ? ` (${profile.branch})` : ""}.`

  const locationChunks = [
    profile.city ?? "",
    profile.state?.name ?? "",
    profile.country?.name ?? "",
  ]

  const location = locationChunks.join(", ")

  switch (platform) {
    case "whatsapp":
    case "telegram": {
      return `${heading}

${renderEmoji("📖")}Subject: ${job.subject}
${
  locationChunks.filter(Boolean).length
    ? `${renderEmoji("📍")}Location: ${location}`
    : ""
}

Apply Here: ${shareURL}

Know someone who might fit the role? Please refer them to us ${renderEmoji(
        "👍"
      )}`
    }
    case "facebook": {
      return `${heading}

${renderEmoji("📖")}Subject: ${job.subject}
${
  locationChunks.filter(Boolean).length
    ? `${renderEmoji("📍")}Location: ${location}`
    : ""
}

Apply Here: ${shareURL}

Know someone who might fit the role? Please refer them to us ${renderEmoji(
        "👍"
      )}
#hiring #teacherrecruitment #teachingjobs #suraasajobs`
    }
    case "linkedin": {
      return `${heading}

${renderEmoji("📖")}Subject: ${job.subject}
${
  locationChunks.filter(Boolean).length
    ? `${renderEmoji("📍")}Location: ${location}`
    : ""
}
      
Apply Here: ${shareURL}
      
Know someone who might fit the role? Tag them in the comments! ${renderEmoji(
        "👍"
      )}
#hiring #teacherrecruitment #teachingjobs #suraasajobs`
    }
    default:
      return ""
  }
}
