import { useContext, useEffect, useState } from "react"

import {
  Button,
  CircularProgress,
  Pagination,
  Typography,
} from "@suraasa/placebo-ui"
import clockIcon from "assets/icons/clockIcon.svg"
import clsx from "clsx"
import { differenceInCalendarDays, format, isToday } from "date-fns"
import { createUseStyles } from "react-jss"
import { Link, useSearchParams } from "react-router-dom"

import api from "api"
import { Application } from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import AcceptApplicationDialog from "components/jobs/AcceptApplicationDialog"
import RejectApplicationDialog from "components/jobs/RejectApplicationDialog"
import NoDataCard from "components/shared/NoDataCard"
import SchoolLogo from "components/shared/SchoolLogo"
import { highlightElement, pluralize } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { TabContext } from "views/Home/TabContext"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "650px",
  },

  tableGrid: {
    display: "grid",
    gridTemplateColumns: "70px 1.75fr 1fr 1fr 1fr",
    zIndex: "2",
    position: "relative",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    transition: "background 225ms",
    scrollMargin: theme.spacing(10),
  },

  schoolName: {
    width: "100%",
    height: "max-content",
    "& > span": {
      justifyContent: "start",
    },
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },

  ellipse: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    background: theme.colors.critical[500],
    display: "inline-block",
  },

  newDecorator: {
    width: "max-content",
    color: theme.colors.critical[500],
    borderRadius: "20px",
    fontSize: "10px",
    fontWeight: theme.typography.fontWeightSemiBold,
    border: `1px solid ${theme.colors.critical[500]}`,
    padding: theme.spacing(0.5, 1),
  },
  clock: {
    position: "absolute",
    alignSelf: "center",
    left: "-36px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

const InvitedTab = ({
  updateNotifications,
}: {
  updateNotifications: () => void
}) => {
  const classes = useStyles()

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const { setOverview } = useContext(TabContext)

  const [applications, setApplications] = useState<
    PaginatedResponse<Application[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const [actionItem, setActionItem] = useState<Application | null>(null)
  const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const inviteId = searchParams.get("inviteId")

  useEffect(() => {
    setLoading(true)
    const listApplicants = async () => {
      const res = await api.jobs.jobApplicant.list({
        params: {
          page,
          application_type: "invited",
          ordering: "-invitation_sent_on",
          status: "invited",
        },
      })
      if (res.isSuccessful) {
        setApplications(res.data)
        setLoading(false)
      }
    }

    listApplicants()
  }, [page])

  useEffect(() => {
    const timer = window.setTimeout(async () => {
      const jobs = applications.data
        .filter(application => application.isViewed === false)
        .map(application => application.id)

      if (jobs.length === 0) return

      const markAsSeenRes = await api.jobs.jobApplicant.markInvitedAsSeen({
        data: { jobApplicants: jobs, isViewed: true },
      })

      if (markAsSeenRes.isSuccessful) updateNotifications()
      else console.error(markAsSeenRes.errors)
    }, 5000)

    return () => {
      window.clearTimeout(timer)
    }
  }, [applications, updateNotifications])

  useEffect(() => {
    if (inviteId && applications.data.length > 0 && !loading) {
      highlightElement({
        element: document.getElementById(inviteId),
        onEnd: () => {
          searchParams.delete("inviteId")
          setSearchParams(searchParams, { replace: true })
        },
      })
    }
  }, [inviteId, applications, loading, searchParams, setSearchParams])

  if (loading) {
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    )
  }

  if (applications.data.length === 0) {
    return (
      <NoDataCard
        className="mt-3"
        message="No schools have invited you yet. But you can still apply for them. Checkout the 'Explore' page."
      />
    )
  }

  return (
    <>
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-2 mr-2")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>
            <Typography variant="strongSmallBody">Date Invited</Typography>
          </div>

          {applications.data.map(item => {
            const inviteExpiry = new Date(item.invitationExpiresAt)
            const timeLeft = differenceInCalendarDays(inviteExpiry, new Date())
            const showExpiry = timeLeft <= 5

            return (
              <div
                className={clsx(
                  classes.tableGrid,
                  classes.tableItem,
                  "py-2 pr-2"
                )}
                id={item.id.toString()}
                key={item.id}
              >
                <div className={classes.clock}>
                  {showExpiry && <img alt="clock" src={clockIcon} />}
                </div>

                <div className="pl-3 mt-0.5">
                  <SchoolLogo
                    className={classes.image}
                    src={item.school.logo}
                  />
                </div>

                <div className="pr-2">
                  <Button
                    className={clsx(classes.schoolName, "-ml-0.75")}
                    component={Link}
                    to={`${routes.schoolProfile.replace(
                      ":slug",
                      item.school.slug
                    )}?jobId=${item.job.id}`}
                    variant="link"
                  >
                    <Typography
                      color="interactive.600"
                      display="inline"
                      variant="smallBody"
                    >
                      {item.school.name}
                    </Typography>
                  </Button>
                  {item.school.state?.name && item.school.country?.name && (
                    <Typography
                      className={clsx(classes.textSmall, "mb-0.5")}
                      color="onSurface.500"
                    >
                      {item.school.state.name}, {item.school.country.name}
                    </Typography>
                  )}

                  <Typography variant="smallBody">
                    {item.job.position}
                  </Typography>

                  <Typography variant="smallBody">
                    {item.job.subject.name}
                  </Typography>
                </div>
                {/* <span
                    className={clsx(
                      classes.newDecorator,
                      "flex items-center gap-0.5 self-baseline"
                    )}
                  >
                    <span className={classes.ellipse} /> NEW
                  </span> */}

                <div className="flex flex-col justify-center">
                  <Typography variant="smallBody">
                    {format(new Date(item.dateApplied), "d MMM yyyy")}
                  </Typography>
                  {showExpiry && (
                    <Typography color="critical.600" variant="smallBody">
                      Invite expires{" "}
                      {!isToday(inviteExpiry) ? (
                        <span>
                          today
                          <br />
                        </span>
                      ) : (
                        <span>
                          in {timeLeft} {timeLeft === 1 ? "day" : "days"}
                          <br />
                          {format(inviteExpiry, "d MMM yyyy")},{" "}
                        </span>
                      )}
                      {format(inviteExpiry, "h:mm a")}
                    </Typography>
                  )}
                </div>
                <div className="flex flex-col justify-center">
                  {item.applicants > 0 && (
                    <Typography variant="smallBody">
                      <b>{item.applicants}</b>{" "}
                      {pluralize("Applicant", item.applicants, {
                        skipCount: true,
                      })}
                    </Typography>
                  )}
                </div>
                <div className="flex items-center justify-end gap-1">
                  <Button
                    color="critical"
                    variant="text"
                    onClick={() => {
                      setActionItem(item)
                      setRejectDialogOpen(true)
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      setActionItem(item)
                      setAcceptDialogOpen(true)
                    }}
                  >
                    Accept Invite
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {actionItem && (
        <RejectApplicationDialog
          afterSubmit={() => {
            setApplications(prevState => ({
              ...prevState,
              data: prevState.data.filter(({ id }) => id !== actionItem.id),
            }))
            setOverview(prevState => ({
              ...prevState,
              totalInvites: prevState.totalInvites - 1,
            }))
            updateNotifications()
          }}
          handleClose={() => {
            setActionItem(null)
            setRejectDialogOpen(false)
          }}
          jobApplicantId={actionItem.id}
          jobPosition={actionItem.job.position}
          mode="invitation"
          open={rejectDialogOpen}
          schoolName={actionItem.school.name}
        />
      )}

      {actionItem && (
        <AcceptApplicationDialog
          afterSubmit={() => {
            setAcceptDialogOpen(false)
            setRejectDialogOpen(false)
            setActionItem(null)
            updateNotifications()
            setApplications(item => ({
              ...item,
              data: item.data.filter(v => v.id !== actionItem?.id),
            }))

            setOverview(prevState => ({
              ...prevState,
              totalInvites: prevState.totalInvites - 1,
              totalActiveApplications: prevState.totalActiveApplications + 1,
            }))
            toast.success("Invite accepted", {
              duration: 7000,
              body: "Please check Active Applications Tab",
            })
          }}
          handleClose={() => {
            setActionItem(null)
            setRejectDialogOpen(false)
          }}
          jobApplicantId={actionItem.id}
          jobPosition={actionItem.job.position}
          open={acceptDialogOpen}
          schoolName={actionItem.job.subject.name}
          subjectName={actionItem.job.subject.name}
        />
      )}
      {applications.total > 10 && (
        <Pagination page={page} total={applications.total} onChange={setPage} />
      )}
    </>
  )
}
export default InvitedTab
