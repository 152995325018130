import React, { useCallback, useMemo, useState } from "react"

import {
  Button,
  CircularProgress,
  Container,
  Pagination,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import metadata from "metadata.json"
import { createUseStyles, useTheme } from "react-jss"
import { createSearchParams, useSearchParams } from "react-router-dom"

import { Filter } from "iconoir-react"

import api from "api"
import { Job } from "api/resources/jobs/types"
import { School } from "api/resources/schools/types"
import { PaginatedResponse } from "api/types"
import Filters, { defaultFilters } from "components/explore/Filters"
import JobCard from "components/explore/JobCard"
import SchoolCard from "components/explore/SchoolCard"
import SearchField from "components/explore/Search"
import Navbar from "components/shared/Navbar"
import NoDataCard from "components/shared/NoDataCard"
import ReactHelmet from "components/shared/ReactHelmet"
import useResources from "utils/hooks/useResources"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  toggleContainer: {
    background: "white",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: "8px",
    width: "max-content",
  },
  toggleButton: {
    width: "100px",
    borderRadius: "8px",
  },
  activeButton: {
    background: theme.colors.primary[500],
    color: "white",
  },
  filters: {
    minWidth: "360px",
    height: "max-content",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  hide: {
    display: "none",
  },
  content: {
    width: "100%",
  },
}))

enum SearchModes {
  school = "School",
  job = "Job",
}

const getMode = (mode: string | null) => {
  if (!mode) return SearchModes.job

  if (Object.values(SearchModes).includes(mode as SearchModes))
    return mode as SearchModes

  return SearchModes.job
}

const Explore = () => {
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()

  const [activeMode, setActiveMode] = useState<SearchModes>(
    getMode(searchParams.get("mode"))
  )

  const { subjects, curricula } = useResources(["subjects", "curricula"])

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1)

  const [schools, setSchools] = useState<PaginatedResponse<School[]>["data"]>({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })
  const [jobs, setJobs] = useState<PaginatedResponse<Job[]>["data"]>({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const theme = useTheme<Theme>()
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"))

  const [showFilters, toggleShowFilters] = useToggle(false)

  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState(searchParams.get("search") || "")
  const [interestedSchoolId, setInterestedSchoolId] = useState(
    searchParams.get("interestedSchoolId") || "-1"
  )

  const registerInterest = async () => {
    const res = await api.schools.interestedUser.create({
      urlParams: {
        schoolId: interestedSchoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Great! Thank you for expressing interest in this school")
    }
    setLoading(false)
  }

  const updateSchoolList = (updatedObj: School) => {
    const registerId = Number(interestedSchoolId)

    if (registerId > 0)
      setSchools(prevState => ({
        ...prevState,
        data: prevState.data.map(x => (x.id === registerId ? updatedObj : x)),
      }))
    else {
      setSchools(prevState => ({
        ...prevState,
        data: prevState.data.map(x =>
          x.id === updatedObj.id ? updatedObj : x
        ),
      }))
    }
  }

  const fetchData = useCallback(
    async (filters: URLSearchParams = new URLSearchParams()) => {
      const fields = filters.getAll("fields")
      if (!fields.includes("date_published"))
        filters.append("fields", "date_published")
      if (!fields.includes("school")) filters.append("fields", "school")

      if (activeMode === SearchModes.school) {
        setLoading(true)

        const res = await api.schools.list({ params: filters })

        if (res.isSuccessful) {
          setSchools(res.data)

          if (Number(interestedSchoolId) > 0) {
            registerInterest()
            setInterestedSchoolId("-1")
            searchParams.set("interestedSchoolId", "-1")
            setSearchParams(createSearchParams(searchParams))

            const objToRegister = res.data.data.find(
              x => x.id === Number(interestedSchoolId)
            )
            if (objToRegister)
              updateSchoolList({ ...objToRegister, interestedInSchool: true })
          }
        }
        setLoading(false)
      } else {
        setLoading(true)
        const res = await api.jobs.list({ params: filters })

        if (res.isSuccessful) {
          setJobs(res.data)
        }
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeMode, interestedSchoolId]
  )

  const switchActiveMode = () => {
    setActiveMode(prevMode =>
      prevMode === SearchModes.school ? SearchModes.job : SearchModes.school
    )
    setPage(1)
  }

  const otherFilters = React.useMemo(
    () => ({
      search: search ?? "",
      mode: activeMode,
      interestedSchoolId,
    }),
    [search, activeMode, interestedSchoolId]
  )

  const filterChoices = useMemo(
    () =>
      defaultFilters.map(filter => {
        if (filter.type === "multi-select") {
          if (filter.id === "subject_id") {
            filter.options = subjects.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }
        if (filter.type === "multi") {
          if (filter.id === "curriculum_board") {
            filter.options = curricula.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }
        return filter
      }),
    [subjects, curricula]
  )

  const renderContent = useCallback(() => {
    if (loading)
      return (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      )

    if (activeMode === SearchModes.school)
      return schools.data.length > 0 ? (
        schools.data.map(item => (
          <SchoolCard data={item} key={item.id} onUpdate={updateSchoolList} />
        ))
      ) : (
        <NoDataCard message="No schools match this criteria. Try changing it up a bit." />
      )
    return jobs.data.length > 0 ? (
      jobs.data.map((job, index) => <JobCard data={job} key={index} />)
    ) : (
      <NoDataCard message="No jobs match this criteria. Try changing it up a bit." />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMode, loading, schools, jobs])

  return (
    <>
      <ReactHelmet data={metadata.explore} />
      <Navbar />
      <Container className="mb-3">
        <div className="flex items-center gap-1.5 mb-3 justify-center">
          <Typography display="inline">Search By</Typography>

          <div className={clsx(classes.toggleContainer, "px-0.25 py-0.25")}>
            <button
              className={clsx(
                classes.toggleButton,
                { [classes.activeButton]: activeMode === SearchModes.school },
                "py-0.5"
              )}
              onClick={switchActiveMode}
            >
              <Typography variant="button">School</Typography>
            </button>

            <button
              className={clsx(
                classes.toggleButton,
                { [classes.activeButton]: activeMode === SearchModes.job },
                "py-0.5"
              )}
              onClick={switchActiveMode}
            >
              <Typography variant="button">Job Title</Typography>
            </button>
          </div>
        </div>

        <SearchField
          onSearch={query => {
            setSearch(query)
            setPage(1)
          }}
        />

        <div className="flex gap-3 flex-col md:flex-row">
          <div>
            {isDownMd && (
              <div className="flex justify-end">
                <Button
                  startAdornment={<Filter />}
                  variant="text"
                  onClick={() => toggleShowFilters()}
                >
                  Filters
                </Button>
              </div>
            )}

            <Filters
              className={clsx(
                classes.filters,
                {
                  hidden: isDownMd && !showFilters,
                },
                "mt-2 md:mt-0"
              )}
              filters={filterChoices}
              otherFilters={otherFilters}
              onChange={newFilters => {
                setPage(1)
                fetchData(newFilters)
              }}
            />
          </div>

          <div className={classes.content}>
            {renderContent()}
            {(activeMode === SearchModes.school ? schools.total : jobs.total) >
              10 && (
              <Pagination
                page={page}
                total={
                  activeMode === SearchModes.school ? schools.total : jobs.total
                }
                onChange={newPage => {
                  setPage(newPage)
                  searchParams.set("page", newPage.toString())
                  fetchData(searchParams)
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default Explore
