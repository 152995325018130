import React, { useEffect, useState } from "react"

import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { ArrowDown } from "iconoir-react"

import api from "api"
import { Job } from "api/resources/jobs/types"
import { tabsProfile } from "utils/constants"
import toast from "utils/toast"

import JobCard from "./JobCard"

const useStyles = createUseStyles(theme => ({
  container: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function JobOpenings({ slug }: { slug: string }) {
  const classes = useStyles()
  const [showAll, setShowAll] = useState(false)
  const [jobList, setJobList] = useState<Job[]>([])
  const [loading, setLoading] = useState(false)
  const [totalJobs, setTotalJobs] = useState<number | null>(0)

  useEffect(() => {
    if (showAll) {
      setLoading(true)
    }
    async function getJobs() {
      const res = await api.jobs.list({
        params: {
          fields: ["date_published"],
          size: 6,
          page: showAll ? "all" : "1",
          school: slug,
        },
      })

      if (res.isSuccessful) {
        setTotalJobs(res.data.total)
        setJobList(res.data.data)
      } else {
        toast.error(res.errors.message || "Something Went Wrong")
      }
      setLoading(false)
    }
    getJobs()
  }, [showAll, slug])

  return (
    <>
      <div
        className={clsx("px-2 py-3.5 sm:px-5 sm:py-4 mt-3", classes.container)}
        id={tabsProfile.jobs}
      >
        <div className="flex items-center justify-between mb-4">
          <Typography variant="title3">Job Openings</Typography>
        </div>

        {jobList.length ? (
          <>
            <div className="sm:grid-cols-2 lg:grid-cols-3 grid gap-3">
              {jobList.map(item => (
                <JobCard
                  {...item}
                  key={item.id}
                  onApply={(id, jobApplicant) =>
                    setJobList(prevState =>
                      prevState.map(job => {
                        if (job.id === id) job.jobApplicant = jobApplicant
                        return job
                      })
                    )
                  }
                />
              ))}
            </div>

            {((totalJobs && totalJobs > 6) || loading) && (
              <>
                <Divider className="mt-3 mb-0.5" color="onSurface.200" />
                <div className="flex justify-end">
                  <Button
                    color="black"
                    endAdornment={<ArrowDown />}
                    loading={loading}
                    variant="text"
                    onClick={() => setShowAll(true)}
                  >
                    View All Job Openings
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <Typography
            className="mb-0.5"
            color="onSurface.400"
            variant="smallBody"
          >
            Looks like there's nothing here
          </Typography>
        )}
      </div>
    </>
  )
}

export default JobOpenings
