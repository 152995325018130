import { Radio, Typography } from "@suraasa/placebo-ui"
import { Controller, useFormContext } from "react-hook-form"

import { TRANSITION_DURATION } from "components/ProfileCompletion/constants"
import SelectableCard from "components/ProfileCompletion/SelectableCard"
import { Form } from "components/ProfileCompletion/utils"

const AreYouCurrentlyTeaching = ({
  handleClick,
}: {
  handleClick: () => void
}) => {
  const { control } = useFormContext<Form>()

  const handleSubmit = () =>
    setTimeout(() => {
      handleClick()
    }, TRANSITION_DURATION)

  return (
    <>
      <Typography variant="strong">Are you currently teaching?</Typography>
      <div className="grid md:grid-cols-2 gap-2 mt-2">
        <Controller
          control={control}
          name="areYouCurrentlyTeaching"
          render={({ field: { value, onChange } }) => (
            <SelectableCard
              className="flex items-center gap-1 col-span-1"
              selected={value === true}
              onClick={() => {
                onChange(true)
                handleSubmit()
              }}
            >
              <Radio checked={value === true} />
              <Typography color="onSurface.800" variant="subtitle2">
                Yes, I am currently teaching
              </Typography>
            </SelectableCard>
          )}
        />

        <Controller
          control={control}
          name="areYouCurrentlyTeaching"
          render={({ field: { value, onChange } }) => (
            <SelectableCard
              className="flex items-center gap-1 col-span-1 row-start-2"
              selected={value === false}
              onClick={() => {
                onChange(false)
                handleSubmit()
              }}
            >
              <Radio checked={value === false} />
              <Typography color="onSurface.800" variant="subtitle2">
                No, but I would love to
              </Typography>
            </SelectableCard>
          )}
        />
      </div>
    </>
  )
}

export default AreYouCurrentlyTeaching
