import React, { PropsWithChildren } from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { JobOfferDetailsType } from "api/resources/jobs/types"

import PreviewFile from "../PreviewFile"

const useStyles = createUseStyles({
  root: {
    "& .PreviewFile-box": {
      width: "min(100%,284px)",
      height: 136,
    },
  },
  details: {
    maxWidth: "calc(146px + 124px + 115px)",
  },
  description: {
    whiteSpace: "pre-wrap",
  },
})

const JobOfferDetails = ({
  children,
  details,
}: PropsWithChildren<{ details: JobOfferDetailsType }>) => {
  const classes = useStyles()

  return (
    <>
      <div className={clsx("mb-4 pt-1", classes.root)}>
        {details.offerLetter && (
          <PreviewFile
            data={details.offerLetter}
            name={details.offerLetterName}
          />
        )}
        {children}
      </div>
    </>
  )
}

export default JobOfferDetails
