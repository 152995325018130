import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Interview } from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Interview>> => {
    try {
      const res = await axios.get(
        urls.interview.retrieve(urlParams.interviewId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
