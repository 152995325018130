import React, { useContext, useState } from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import { Context } from "GlobalState"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"

import api from "api"
import Heading from "components/ProfileCompletion/Heading"
import { routes } from "utils/routes"
import toast from "utils/toast"

import { ProfileCompletionContext } from "../utils"

const useStyles = createUseStyles(theme => ({
  root: {
    "& *": {
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "start",
      },
    },
  },
}))

const Congratulations = ({
  setStepsOverview,
  jobId,
  preventRedirect = false,
}: Pick<Context, "setStepsOverview"> & {
  preventRedirect?: boolean
  jobId?: number
}) => {
  const classes = useStyles()

  const navigate = useNavigate()
  const [applyLoading, setApplyLoading] = useState(false)
  const { toggle } = useContext(ProfileCompletionContext)

  const applyForJob = async () => {
    if (jobId) {
      setApplyLoading(true)
      const res = await api.jobs.jobApplicant.create({
        urlParams: { jobId },
      })

      if (res.isSuccessful) {
        toast.success("Applied for Job Successfully")
        /**
         * we want to keep the `Applied` state in sync
         * when user applies through either of the cards
         */
        return setTimeout(
          () =>
            window.open(
              window.location.origin + window.location.pathname,
              "_self"
            ),
          500
        )
      }

      toast.error(res.errors.message || "Something went wrong")
    }
    setApplyLoading(false)
  }

  const handleGoToJobsPlatform = async () => {
    if (toggle) toggle()

    if (jobId) {
      await applyForJob()
      setStepsOverview({
        marketingFormDataExists: true,
        profileDataExists: true,
        marketingSignupComplete: true,
        qualificationDataExists: true,
        loading: false,
      })
    } else {
      setStepsOverview({
        marketingFormDataExists: true,
        profileDataExists: true,
        marketingSignupComplete: true,
        qualificationDataExists: true,
        loading: false,
      })

      if (!preventRedirect) navigate(routes.home, { replace: true })
    }
  }

  return (
    <div className={classes.root}>
      <Heading
        subtitle="Now you can apply for jobs in your favorite schools."
        title="Congratulations"
      />
      <Typography>
        You can also add more data in your profile, to make your profile stand
        out.
      </Typography>
      <Typography className="mt-1.25" color="onSurface.500">
        Secret Tip: Schools always loves more detailed profiles as compared to
        less detailed
      </Typography>
      <div className="flex flex-col md:flex-row items-center  gap-2 mt-4.5">
        <Button
          component="a"
          href={process.env.REACT_APP_SSO_URL}
          // @ts-ignore @suraasa/placebo-ui
          target="_blank"
          variant={jobId ? "text" : "filled"}
        >
          Add More Details in Profile
        </Button>
        <Button
          loading={applyLoading}
          size="lg"
          variant={jobId ? "filled" : "text"}
          onClick={handleGoToJobsPlatform}
        >
          {jobId ? "Proceed with application" : "Go to jobs platform"}
        </Button>
      </div>
    </div>
  )
}

export default Congratulations
