import React, { FC, useEffect, useState } from "react"

import api from "api"

type Props = {
  items: {
    slug: string
    type: "course" | "qualification" | "certification"
  }[]
}

const CheckEnrollment: FC<Props> = ({ children, items }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    api.enrollments
      .validate({
        data: { items },
      })
      .then(data => {
        if (data.isSuccessful) {
          setVisible(data.data.some(element => element.enrolled))
        }
      })
  }, [items])

  return visible ? <>{children}</> : null
}

export default CheckEnrollment
