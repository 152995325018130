import React from "react"

import {
  Amenity,
  Gallery,
  Perk,
  SchoolProfile,
} from "api/resources/schools/types"
import { UseArray } from "utils/hooks/useArray"

type ProfileItem<T> = {
  data: UseArray<T>["array"]
  set: UseArray<T>["set"]
}

type Context = {
  profile: SchoolProfile
  gallery: ProfileItem<Gallery>
  amenities: ProfileItem<Amenity>
  perks: ProfileItem<Perk>
}

const defaultProfileItem = {
  data: [],
  set: () => {},
}

export const SchoolProfileContext = React.createContext<Context>({
  profile: {
    id: 0,
    branch: null,
    name: "",
    slug: "",
    website: "",
    email: "",
    phoneNumber: null,
    address: "",
    description: "",
    logo: "",
    city: null,
    pincode: null,
    coverImage: "",
    dateEstablished: "",
    workingDays: [],
    dayStartTime: "",
    dayEndTime: "",
    teachingMode: 3,
    isVerified: false,
    galleryCount: 0,
    managementMessage: {
      message: "",
      name: "",
      position: "",
      image: "",
      id: Date.now(),
      school: 0,
    },
    curriculumBoard: [],
    gallery: [],
    perks: [],
    amenities: [],
    state: null,
    country: null,
  },
  gallery: defaultProfileItem,
  amenities: defaultProfileItem,
  perks: defaultProfileItem,
})

export default SchoolProfileContext
